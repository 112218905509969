import * as React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

const ProfileEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <TextInput source="phone" />
    </SimpleForm>
  </Edit>
);

//TODO: set for all permissions
export const Profile = {
  name: "profile",
  edit: ProfileEdit,
  //list: undefined,
  roles: ["all"],
};
