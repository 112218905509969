import { UploadsAPI } from "../../uploader/api";

function getSelectedUploadFile(state) {
  const {
    uploader: { selectedUpload },
    files: { [selectedUpload]: selectedUploadFile },
  } = state;
  // Validate it right here. Return undefined if invalid...
  if (!selectedUploadFile) {
    return undefined;
  }
  if (!selectedUploadFile.upload_completed) {
    return undefined;
  }
  if (selectedUploadFile.publishing_status) {
    // Already submitted or published.
    return undefined;
  }
  return selectedUploadFile;
}

export const PublishingActions = {
  FILE_SUBMIT: "FILE_SUBMIT",
  FILE_SUBMIT_OK: "FILE_SUBMIT_OK",

  publishLecture(params) {
    const { skipIntro, lectureId } = params;
    return async (dispatch, getState) => {
      const state = getState();
      const selectedFile = getSelectedUploadFile(state);
      if (lectureId < 0) {
        setTimeout(() => {
          window.alert("Invalid lecture id.");
        }, 0);
        return;
      }
      if (!selectedFile) {
        setTimeout(() => {
          window.alert("Please select an uploaded file to publish.");
        }, 0);
        return;
      }
      dispatch(
        PublishingActions.submitFile({
          fileId: selectedFile.id,
          lectureId,
          skipIntro,
        }),
      );
    };
  },

  submitFile(params) {
    const { fileId, lectureId, skipIntro = false } = params;
    return async dispatch => {
      dispatch({ type: PublishingActions.FILE_SUBMIT, payload: params });
      await UploadsAPI.publish(fileId, {
        lectureId,
        skipIntro,
      });
      dispatch({ type: PublishingActions.FILE_SUBMIT_OK, payload: params });
    };
  },
};
