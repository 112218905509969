import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { UIActions, FileActions } from "../state/actions";
import UploaderWrapper from "./UploaderWrapper";


export default connect(
  function mapState(state) {
    const { ui: { mainTabIndex = 0 } = {} } = state;
    return {
      mainTabIndex,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: bindActionCreators(
        {
          selectTab: UIActions.selectTab,
          loadAllFiles: FileActions.loadAllFiles,
        },
        dispatch,
      ),
    };
  }
)(UploaderWrapper);
