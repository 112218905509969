import * as React from "react";
import { createElement } from "react";
import { useSelector } from "react-redux";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { MenuItemLink, getResources } from "react-admin";
import { withRouter } from "react-router-dom";
import LabelIcon from "@material-ui/icons/Label";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { makeStyles } from "@material-ui/core/styles";
import lodashGet from "lodash/get";
import classnames from "classnames";
import { sidebarCustomItems } from "../../resources";
import rmw from "../../assets/img/rmw_background.png";

const MENU_WIDTH = 240;
const CLOSED_MENU_WIDTH = 55;
const useStyles = makeStyles(
  theme => ({
    main: {
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: 0
    },
    open: {
      width: lodashGet(theme, "menu.width", MENU_WIDTH)
    },
    closed: {
      width: lodashGet(theme, "menu.closedWidth", CLOSED_MENU_WIDTH)
    },
    menuItemLink: {
      paddingTop: 10,
      paddingBottom: 10
    }
  }),
  { name: "RaMenu" }
);

const Menu = ({ onMenuClick, logout, ...rest }) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div
      className={classnames(classes.main, {
        [classes.open]: open,
        [classes.closed]: !open
      })}
    >
      {open && (
        <Box
          style={{
            padding: 20,
            borderBottom: "solid 1px #E4E7EB"
          }}
        >
          <img
            style={{
              borderRadius: "50%",
              width: 100
            }}
            src={rmw}
            alt="rabbi-img"
          />
          <Typography
            style={{
              color: "#66788A",
              fontFamily: "Roboto",
              fontSize: 12
            }}
          >
            RMW APP
          </Typography>
          <Typography
            style={{
              color: "#3A3B3F",
              fontFamily: "Roboto",
              fontSize: 16
            }}
          >
            Rabbi Moshe Weinberger
          </Typography>
        </Box>
      )}
      <Box
        style={{
          paddingTop: 20,
          paddingLeft: 10
        }}
      >
        <MenuItemLink
          className={classes.menuItemLink}
          to={`/dashboard`}
          primaryText="Dashboard"
          leftIcon={<DashboardIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        {resources
          .filter(r => r.hasList)
          .filter(r => r.options.hideMenu !== true)
          .map(resource => (
            <MenuItemLink
              className={classes.menuItemLink}
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) || resource.name
              }
              leftIcon={
                resource.icon ? createElement(resource.icon) : <LabelIcon />
              }
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          ))}
        {sidebarCustomItems.map(resource => (
          <MenuItemLink
            className={classes.menuItemLink}
            key={resource.name}
            to={`/${resource.to}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={
              resource.icon ? createElement(resource.icon) : <LabelIcon />
            }
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
        <MenuItemLink
          className={classes.menuItemLink}
          key="random"
          to={`/homepage-settings`}
          primaryText="Homepage settings"
          leftIcon={<HomeOutlinedIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      </Box>
      {isXSmall && logout}
    </div>
  );
};

export default withRouter(Menu);
