import React from "react";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";

export function CategoryHierarchy({ category }) {
  const catArr = [];
  let cat = category;

  while (cat) {
    catArr.unshift(cat);
    cat = cat.parentCategory;
  }

  return (
    <div>
      {catArr.map((c, i) => (
        <Fragment key={c.id}>
          <NavLink to={`/categories/${c.id}`}>{c.name}</NavLink>
          {i !== catArr.length - 1 && <span> &gt; </span>}
        </Fragment>
      ))}
    </div>
  );
}
