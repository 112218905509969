import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'url(/images/login.jpeg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  ChangePasswordMain: {
    minHeight: 'inherit',
    background: 'none',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 560,
    width: 496,
    backgroundColor: 'rgba(255,255,255,0.88)',
    boxShadow: [[0, 2, 12, 5, 'rgba(0,0,0,0.2)']],
    padding: '32px 48px',
  },
  paperForgotPassword: {
    height: 315,
    width: 495,
  },
  paperInstructions: {
    height: 235,
    width: 430,
    padding: '57px 48px',
  },
  paperReset: {
    height: 400,
    // padding: '57px 48px',
  },
  paperChangePassword: {
    height: 400,
    padding: '32px 48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submitWrapper: {
    marginTop: 65,
    textAlign: 'center',
  },
  submit: {
    height: 48,
    width: 167,
    fontSize: 15,
    textTransform: 'capitalize',
    backgroundColor: '#24B7F6',
    '&:hover': {
      backgroundColor: '#24B7F6',
    },
  },
  logo: {
    height: 117,
  },
  welcome: {
    color: '#001543',
    fontSize: 45,
    fontWeight: 300,
  },
  welcomeForgotPass: {
    fontSize: 24,
    width: '100%',
  },
  welcomeInstructions: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 15,
  },
  forgotSubmitWrapper: {
    marginTop: 45,
    textAlign: 'right',
    marginBottom: 35,
  },
  signIn: {
    color: '#829CB5',
    fontSize: 17,
    fontWeight: 500,
  },
  instructionsMsg: {
    fontSize: 14,
    width: 300,
    marginBottom: 20,
  },
  resetMsg: {
    fontSize: 14,
    marginBottom: 20,
    width: '100%',
  },
  recoveryLink: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: '#24B7F6',
  },
  version: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    padding: 5,
    '&:hover': {
      opacity: 1,
    },
  },
}));
