import React from "react";
import classNames from "classnames";
import ReactModal from "react-modal";
import { closestAttribVal } from "../lib";

export class TransferFromUserModal extends React.Component {
  // #region Private vars, state
  //
  /** Non-state variable. DO NOT use this variable for rendering. */
  gettingUsers = false;
  /** Non-state variable. DO NOT use this variable for rendering. */
  transferring = false;
  state = {
    searchTerm: "",
    searchTermPartsLower: undefined,
    selectedFileId: undefined,
    selectedUserId: undefined
  };
  // #endregion
  // #region Event Handlers
  //
  /** @param {React.ChangeEvent<HTMLInputElement>} e */
  onChangeSearchTerm = e => {
    const searchTerm = e.target.value;
    this.setState({
      searchTerm,
      searchTermLower: searchTerm.toLowerCase()
    });
  };
  onClickOK = () => {
    if (this.validateOK()) {
      this.confirmAndTransferFile();
    }
  };
  /** @param {React.MouseEvent<HTMLLIElement>} e */
  onClickFile = e => {
    const id = closestAttribVal(e.currentTarget, "data-id");
    this.setState({
      selectedFileId: id
    });
  };
  /** @param {React.MouseEvent<HTMLLIElement>} e */
  onDoubleClickFile = e => {
    const id = closestAttribVal(e.currentTarget, "data-id");
    this.setState({
      selectedFileId: id
    });
    this.confirmAndTransferFile();
  };
  /** @param {React.MouseEvent<HTMLLIElement>} e */
  onClickUser = e => {
    const id = closestAttribVal(e.currentTarget, "data-id");
    this.setState({
      selectedFileId: undefined,
      selectedUserId: id
    });
  };
  onRequestClose = () => {
    if (this.transferring) {
      return;
    }
    this.setState({
      searchTerm: "",
      searchTermPartsLower: undefined,
      selectedUserId: undefined
    });
    this.props.onRequestClose();
  };
  // #endregion
  // #region Component methods
  //
  componentDidUpdate(prevProps /* , prevState */) {
    if (prevProps !== this.props) {
      this.maybeGetUsers();
    }
  }
  async confirmAndTransferFile() {
    if (this.transferring || !window.confirm("Are you sure?")) {
      return;
    }
    const {
      props: {
        actions: { transferFromUser }
      },
      state: { selectedFileId, selectedUserId }
    } = this;
    this.transferring = true;
    await transferFromUser(selectedFileId, selectedUserId).catch(err => {
      this.transferring = false;
      window.alert("There was an error. Support has been notified.");
    });
    this.transferring = false;
    // TODO: Call the transfer action and wait here for it to succeed.
    this.onRequestClose();
  }
  async getUsers() {
    const {
      actions: { getUsersWithFiles }
    } = this.props;
    await getUsersWithFiles();
  }
  async maybeGetUsers() {
    const { isOpen } = this.props;
    if (isOpen && !this.gettingUsers) {
      this.gettingUsers = true;
      await this.getUsers();
      this.gettingUsers = false;
    }
  }
  validateOK() {
    const {
      state: { selectedUserId }
    } = this;
    if (!selectedUserId) {
      window.alert("Please select a user.");
      return false;
    }
    return true;
  }
  render() {
    const {
      props: { isOpen, loadingUsersWithFiles, usersWithFiles },
      state: { searchTerm, searchTermLower, selectedUserId, selectedFileId }
    } = this;
    if (!isOpen) {
      return null;
    }
    const files =
      usersWithFiles && selectedUserId
        ? usersWithFiles[selectedUserId].files
        : undefined;
    const selectedFile =
      files && selectedFileId
        ? files[selectedFileId] || { name: "" }
        : { name: "" };
    return (
      <ReactModal
        ariaHideApp={false}
        contentLabel="Transfer File"
        isOpen={isOpen}
        onRequestClose={this.onRequestClose}
        shouldReturnFocusAfterClose={false}
        style={{
          content: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 50,
            padding: 0,
            paddingBottom: 50
          }
        }}
      >
        <div className="modal_header">
          <h3>Transfer File</h3>
          <div className="modal_header_buttons">
            <button onClick={this.onClickOK}>OK</button>{" "}
            <button onClick={this.onRequestClose}>Cancel</button>
          </div>
          <div className="modal_header_body">
            <center>
              <strong>
                <em>{selectedFile.name}</em>
              </strong>
            </center>
            <input
              type="text"
              value={searchTerm}
              onChange={this.onChangeSearchTerm}
              autoFocus={true}
              placeholder="Search: Name | Email"
              style={{ marginTop: 10, width: "100%" }}
            />
          </div>
        </div>
        <div className="modal_body">
          {loadingUsersWithFiles && (
            <center>
              <strong>
                <em>Loading...</em>
              </strong>
            </center>
          )}
          <ul className="user_list">
            {usersWithFiles &&
              Object.keys(usersWithFiles).map(userId => {
                const user = usersWithFiles[userId];
                const filesCount = Object.keys(user.files).length;
                if (
                  searchTerm &&
                  !(
                    user.name.toLowerCase().indexOf(searchTermLower) > -1 ||
                    user.email.toLowerCase().indexOf(searchTermLower) > -1
                  )
                ) {
                  return null;
                }
                return (
                  <li
                    key={userId}
                    className={classNames({
                      active: userId === selectedUserId
                    })}
                    data-id={userId}
                    onClick={this.onClickUser}
                  >
                    {user.name}
                    <span
                      style={{
                        float: "right",
                        textAlign: "right",
                        width: 25
                      }}
                    >
                      {filesCount}
                    </span>
                    <span style={{ float: "right" }}>{user.email}</span>
                  </li>
                );
              })}
          </ul>
          {selectedUserId && (
            <div style={{ marginTop: 20 }}>
              <strong>Files:</strong>
              <ul className="user_list" style={{ marginTop: 20 }}>
                {Object.keys(files).map(fileId => {
                  const file = files[fileId];
                  return (
                    <li
                      key={fileId}
                      className={classNames({
                        active: fileId === selectedFileId
                      })}
                      data-id={fileId}
                      onClick={this.onClickFile}
                      onDoubleClick={this.onDoubleClickFile}
                    >
                      {file.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </ReactModal>
    );
  }
  // #endregion
}
