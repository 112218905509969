import { Box, Link, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  ListView,
  TextField,
  useListController
} from "react-admin";
import { getMMDDYYYYFromDate } from "../utils";

const defaultProps = {
  perPage: 25
};
const bannerListProps = {
  resource: "banners",
  basePath: "/banners",
  filter: {},
  ...defaultProps
};
const sponsorshipListProps = {
  resource: "sponsorships",
  basePath: "/sponsorships",
  filter: {},
  ...defaultProps
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BannerList = (props: any) => {
  const controllerProps = useListController(bannerListProps);

  return (
    <ListContextProvider value={controllerProps}>
      <ListView {...props} {...controllerProps} exporter={false}>
        <Datagrid rowClick="edit" /*edit={<BannerEdit />}*/>
          <TextField source="title" label="Banner" />
          <TextField source="link_type" label="Link Type" />
          <TextField source="link_id" label="Link ID" />
          <FunctionField
            render={(record: any) =>
              !!record.file ? (
                <Link href={record.file.url} target="_blank">
                  Banner Link
                </Link>
              ) : (
                <span>&nbsp;</span>
              )
            }
            label="Banner"
          />
          <FunctionField
            label="Active dates"
            render={(record: any) =>
              `${getMMDDYYYYFromDate(
                new Date(record.display_start)
              )} - ${getMMDDYYYYFromDate(new Date(record.display_end))}`
            }
          />
        </Datagrid>
      </ListView>
    </ListContextProvider>
  );
};

const SponsorshipList = (props: any) => {
  const sponsorshipControllerProps = useListController(sponsorshipListProps);
  return (
    <ListContextProvider value={sponsorshipControllerProps}>
      <ListView {...props} {...sponsorshipControllerProps} exporter={false}>
        <Datagrid rowClick="edit">
          <TextField source="sponsorship_message" />
          <FunctionField
            label="Active dates"
            render={(record: any) =>
              `${getMMDDYYYYFromDate(
                new Date(record.start_date)
              )} - ${getMMDDYYYYFromDate(new Date(record.end_date))}`
            }
          />
        </Datagrid>
      </ListView>
    </ListContextProvider>
  );
};

export const HomepageSettings = (props: any) => {
  // const classes = useStyles();

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Tabs
        style={{
          margin: "0 -40px",
          padding: "0 40px",
          borderBottom: "1px solid #E4E7EB"
        }}
        value={tabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab
          label="Banners"
          style={{
            boxShadow: "none"
          }}
        />
        <Tab label="Sponsorships" style={{ boxShadow: "none" }} />
      </Tabs>
      {/* TODO: create separate components for these and use useListController */}
      <TabPanel value={tabIndex} index={0}>
        <BannerList {...props} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <SponsorshipList {...props} />
      </TabPanel>
    </>
  );
};
