/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import { ToastContainer } from "react-toastify";
//import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Joiner, Trimmer, Uploader, UrlUploader } from "./screens";
import { Box, Paper, Tab, Tabs, Typography } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

class UploaderWrapper extends React.PureComponent {
  state = {
    value: 0
  };

  componentDidMount() {
    this.props.actions.loadAllFiles();
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const {
      state: { value }
    } = this;
    return (
      <React.Fragment>
        <Tabs value={value} onChange={this.handleChange}>
          {/* <TabList className="tool_header"> */}
          <Tab label="Upload" {...a11yProps(0)} />
          <Tab label="Trim" {...a11yProps(1)} />
          <Tab label="Join" {...a11yProps(2)} />
          <Tab label="Url" {...a11yProps(3)} />
          <Tab label="Vimeo ID" {...a11yProps(4)} />
        </Tabs>

        {/* </TabList> */}
        <Paper
          style={{
            padding: 15,
            height: "calc(100% - 80px)"
          }}
        >
          <TabPanel value={value} index={0}>
            <Uploader />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Trimmer />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Joiner />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <UrlUploader />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Typography component="div">Coming soon</Typography>
          </TabPanel>
        </Paper>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
export default UploaderWrapper;
