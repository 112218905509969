import { makeStyles } from "@material-ui/core";
import StorageIcon from "@material-ui/icons/Storage";
import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  FunctionField,
  ImageField,
  ImageInput,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from "react-admin";
import { ColorField, ColorInput } from "react-admin-color-input";
import { CategoryHierarchy } from "./../components/common/CategoryHierarchy";

const ListFilters = (props: any) => (
  <Filter variant="outlined" {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);

const useStyles = makeStyles(theme => ({
  image: {
    "& img": {
      maxHeight: 75
    }
  }
}));

const CategoryList = (props: any) => {
  const classes = useStyles();
  return (
    <List {...props} filters={<ListFilters />} exporter={false}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <ReferenceField source="parent" reference="categories" link={false}>
          <FunctionField
            render={(record: any) => <CategoryHierarchy category={record} />}
          />
        </ReferenceField>
        {/* <NumberField source="parent" /> */}
        <NumberField source="sort_order" />
        <ColorField source="color" />
        {/* <TextField source="icon_file" /> */}
        <ImageField
          className={classes.image}
          source="icon_file.url"
          label="Icon image"
        />
        <ImageField
          className={classes.image}
          source="banner_file.url"
          label="Banner image"
        />
      </Datagrid>
    </List>
  );
};

const CategoryCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ImageField source="icon_file.url" label="Icon image" />
      <ImageInput
        source="FILE_icon"
        label="Icon Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceInput perPage={-1} source="parent" reference="categories">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="sort_order" />
      <ColorInput source="color" />
      {/* <TextInput source="icon_file" />
      <TextInput source="banner_file" /> */}
      <ImageField source="banner_file.url" label="Banner image" />
      <ImageInput
        source="FILE_banner"
        label="Banner Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
const CategoryEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ImageField source="icon_file.url" label="Icon image" />
      <ImageInput
        source="FILE_icon"
        label="Icon Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <ReferenceInput perPage={-1} source="parent" reference="categories">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="sort_order" />
      <ColorInput source="color" />
      {/* <TextInput source="icon_file" />
      <TextInput source="banner_file" /> */}
      <ImageField source="banner_file.url" label="Banner image" />
      <ImageInput
        source="FILE_banner"
        label="Banner Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const Categories = {
  name: "categories",
  options: { label: "Categories" },
  icon: StorageIcon,
  list: CategoryList,
  edit: CategoryEdit,
  create: CategoryCreate,
  roles: ["all"]
};
