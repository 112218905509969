import { UserActions } from "../actions";

const initialState = {
  email: "",
  id: "",
  name_first: "",
  name_last: "",
  upload_zone: "sfo2",
  role: {}
};

export function UserState(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case [UserActions.USER_SIGNIN_COMPLETE]:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}