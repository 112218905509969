import resourceIcon from "@material-ui/icons/MovieFilter";
import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput
} from "react-admin";
import { BulkActionsView, PublishingStatuses } from "./shared";

function FiltersView(props: any) {
  return (
    <Filter {...props}>
      <TextInput label="Lecture ID" source="lecture_id" alwaysOn />
      <TextInput label="Title" source="lecture_title" alwaysOn />
      <TextInput label="Speaker" source="speaker_name" alwaysOn />
    </Filter>
  );
}

function ListView(props: any) {
  return (
    <List
      {...props}
      filters={<FiltersView />}
      bulkActionButtons={<BulkActionsView />}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}
      title={
        <span>
          Rendering
          {/* <small style={{ fontSize: 12, float: "right" }}>
            Auto refresh: 30 seconds
          </small> */}
        </span>
      }
    >
      <Datagrid rowClick="show">
        <TextField source="lecture_id" />
        <TextField source="lecture_title" />
        <TextField source="speaker_name" />
        <SelectField source="publishing_status" choices={PublishingStatuses} />
        <TextField source="name" />
        <TextField source="size" />
        <TextField source="user_id" />
        <TextField source="id" />
        <TextField source="mime_type" />
        <TextField source="created" />
      </Datagrid>
    </List>
  );
}

function ShowView(props: any) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Rendering">
          <TextField source="lecture_id" />
          <TextField source="lecture_title" />
          <TextField source="speaker_name" />
          <SelectField
            source="publishing_status"
            choices={PublishingStatuses}
          />
          <TextField source="name" />
          <TextField source="size" />
          <TextField source="user_id" />
          <TextField source="mime_type" />
          <DateField source="created" showTime />
          <NumberField source="upload_time" />
          <TextField source="vimeo_status" />
          <TextField source="duration" />
          <BooleanField source="has_audio" />
          <TextField source="media_type" />
          <TextField source="error" />
          <BooleanField source="is_clip" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const Rendering = {
  category: "database",
  name: "uploads-rendering",
  icon: resourceIcon,
  list: ListView,
  options: { label: "Rendering" },
  show: ShowView,
  roles: ["all"]
};
