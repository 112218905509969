import { ReactComponentLike } from "prop-types";
import React from "react";
import { CustomRoutes, RouteWithoutLayout } from "react-admin";
import { Route } from "react-router-dom";
import {
  Banners,
  Categories,
  Dashboard,
  ForgotPassword,
  HomepageSettings,
  Lectures,
  LiveEvents,
  Profile,
  Rendered,
  // Rendering,
  Series,
  SetPassword,
  Speakers,
  Sponsorships,
  Users
} from "../pages";
import { RenderResource } from "../server";

interface MenuItem {
  key: string;
  name: string;
  icon: ReactComponentLike;
  to: string;
}
export const sidebarCustomItems: MenuItem[] = [
  // {
  //   key: Dashboard.name,
  //   name: Dashboard.name,
  //   icon: Dashboard.icon,
  //   to: Dashboard.to
  // },
];

const _resources = [
  Users,
  Lectures,
  Rendered,
  // Rendering,
  Speakers,
  Profile,
  LiveEvents,
  // Locations,
  Banners,
  Sponsorships,
  Categories,
  Series,
  {
    name: "series_lectures",
    roles: ["all"]
  }
];

export const customRoutes: CustomRoutes = [
  <Route
    exact
    path="/dashboard"
    render={(props: any) => <Dashboard {...props} />}
  />,
  <Route
    exact
    path="/set-password/:token"
    render={(props: any) => <SetPassword {...props} />}
  />,
  <RouteWithoutLayout
    exact
    path="/forgot-password"
    render={(props: any) => <ForgotPassword {...props} />}
    noLayout
  />,
  <Route
    exact
    path="/homepage-settings"
    render={(props: any) => <HomepageSettings {...props} />}
  />
];

export const resources = _resources
  .sort((a: any, b: any) => a.sort - (b.sort || 9999))
  .map((props: any) => {
    //console.log({ name: props.name });

    return <RenderResource key={props.name} {...props} />;
  });
