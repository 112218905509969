import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  useRedirect,
  useNotify,
  Notification,
  useAuthenticated,
} from "react-admin";
import { authClient } from "../server";
import { TextField, Button, CssBaseline, Typography } from "@material-ui/core";
import { useStyles } from "./Login.styles";

export const SetPassword = ({ match = {}, ...rest }) => {
  useAuthenticated({ page: "forgot-password" });
  const { params: { token } = {} } = match;
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    if (!token) {
      notify("Missing token", "warning");
      redirect("/login");
    } else {
      setState((s) => ({ ...s, token }));
    }
  }, [token, notify, redirect]);
  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  };
  const submit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword, token } = state;
    if (password !== confirmPassword) {
      notify("Passwords do not match", "warning");
      return;
    }
    try {
      await authClient.post(
        "auth/password/reset",
        {
          password,
          confirm_password: confirmPassword,
          token,
        },
        null,
        false
      );
      redirect("/login");
    } catch (error) {
      notify("ra.notification.http_error", "warning");
    }
  };
  const { password, confirmPassword } = state;
  return (
    <main className={classes.main}>
      <CssBaseline />
      <div className={clsx(classes.paper, classes.paperReset)}>
        <Typography
          component="h1"
          className={clsx(classes.welcome, classes.welcomeForgotPass)}
          align="left"
        >
          Set Password
        </Typography>
        <Typography
          component="h2"
          className={clsx(classes.signIn, classes.resetMsg)}
          align="left"
        >
          Enter your new password here.
        </Typography>
        <form className={classes.form} noValidate onSubmit={submit}>
          <TextField
            margin="dense"
            required
            fullWidth
            label="New Password (Minimum 8 Characters)"
            name="password"
            value={password}
            autoFocus
            onChange={handleChange}
            type="password"
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            value={confirmPassword}
            type="password"
            onChange={handleChange}
          />
          <div className={classes.forgotSubmitWrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                !password ||
                !confirmPassword ||
                password.length < 8 ||
                password !== confirmPassword
              }
              size="large"
            >
              Set Password
            </Button>
          </div>
        </form>
      </div>
      <Notification />
    </main>
  );
};
