import { FileActions } from "../actions";
import { UploaderActions } from "../actions";
import { PublishingActions } from "../actions";

const initialState = {
  loading: false,
  loadingUsers: false,
  loadingUsersWithFiles: false,
  selectedUpload: "",
  users: undefined,
  usersWithFiles: undefined
};

export function UploaderState(state = initialState, action) {
  const { type, payload, loading } = action;
  switch (type) {
    case FileActions.FILE_DELETE:
      if (payload !== state.selectedUpload) {
        return state;
      }
      return {
        ...state,
        selectedUpload: ""
      };
    case FileActions.FILES_LOAD_ALL:
      return {
        ...state,
        loading: action.payload
      };
    case PublishingActions.FILE_SUBMIT:
      if (state.selectedUpload !== action.payload.fileId) {
        return state;
      }
      // The selected file is being submitted. Unselect it now.
      return {
        ...state,
        selectedUpload: ""
      };
    case UploaderActions.UPLOAD_SELECT:
      const selectedUpload =
        state.selectedUpload === action.payload ? "" : action.payload;
      return {
        ...state,
        selectedUpload
      };
    case UploaderActions.UPLOAD_USERS_GET:
      return {
        ...state,
        loadingUsers: loading,
        users: payload
      };
    case UploaderActions.UPLOAD_USERS_WITH_FILES_GET:
      return {
        ...state,
        loadingUsersWithFiles: loading,
        usersWithFiles: payload
      };
    default:
      return state;
  }
}
