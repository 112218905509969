import React from "react";

import {
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  Create,
} from "react-admin";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// Local
// import { useStyles } from "./Locations.styles.js";
const LocationList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
);

const LocationEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);
const LocationCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
export const Locations = {
  name: "Locations",
  list: LocationList,
  icon: LocationOnIcon,
  edit: LocationEdit,
  create: LocationCreate,
  roles: ["all"],
};
