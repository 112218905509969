export const authRoles = [
  {
    id: "super_admin",
    name: "Super Admin",
    prop: "isSuperAdmin",
  },
  {
    id: "org_admin",
    name: "Org Admin",
    prop: "isOrgAdmin",
  },
];
