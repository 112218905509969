import React, { useEffect, useState } from "react";
import { useDataProvider, UserMenu, MenuItemLink } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import { authUser } from "../../server";
import Avatar from "react-avatar";

const CustomAvatar = ({ firstName, lastName }) => (
  <Avatar
    size={40}
    style={{ fontSize: 16 }}
    round={true}
    name={`${firstName} ${lastName}`}
  />
);

export const CustomUserMenu = props => {
  const dataProvider = useDataProvider();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  useEffect(() => {
    if (!authUser.id) return;

    dataProvider
      .getOne("profile", { id: authUser.id })
      .then(({ data }) => {
        const { id, email, first_name, last_name } = data;
        setFirstName(first_name);
        setLastName(last_name);
        localStorage.setItem(
          "auth",
          JSON.stringify({
            id,
            fullName: `${first_name} ${last_name}`,
            email,
            first_name,
            last_name
          })
        );
      })
      .catch(error => {
        console.error({ error });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser?.id]);
  return (
    <>
      <UserMenu
        {...props}
        icon={<CustomAvatar firstName={firstName} lastName={lastName} />}
        style={{ userButton: { background: "red" } }}
      >
        <MenuItemLink
          to={`/profile/${authUser.id}`}
          primaryText="My profile"
          leftIcon={<SettingsIcon />}
        />
      </UserMenu>
    </>
  );
};
