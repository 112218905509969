import React from "react";

import {
  Datagrid,
  DateField,
  BooleanField,
  Edit,
  List,
  SimpleForm,
  TextInput,
  DateInput,
  FunctionField,
  Create
} from "react-admin";
// Local
// import { useStyles } from "./Sponsorships.styles.js";
const SponsorshipList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <DateField source="event_title" label="Event" />
      <BooleanField source="active" label="Status" />
      <DateField source="event_start" label="Date" />
      <FunctionField
        render={record =>
          // TODO: may need to check if same day, and if not, to include day/date in the display
          [record.event_start, record.event_end]
            .filter(t => !!t)
            .map(t => new Date(t).toLocaleTimeString())
            .join(" - ")
        }
        label="Time"
      />
    </Datagrid>
  </List>
);

const SponsorshipCreate = props => (
  <Create {...props}>
    <SponsorForm />
  </Create>
);
const SponsorshipEdit = props => (
  <Edit {...props}>
    <SponsorForm />
  </Edit>
);

const SponsorForm = props => (
  <SimpleForm {...props} variant="outlined">
    <DateInput source="start_date" />
    <DateInput source="end_date" />
    <TextInput source="sponsorship_message" multiline />
  </SimpleForm>
);
export const Sponsorships = {
  name: "sponsorships",
  options: { label: "Sponsorships", hideMenu: true },
  list: SponsorshipList,
  edit: SponsorshipEdit,
  create: SponsorshipCreate,
  roles: ["all"]
};
