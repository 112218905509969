import React from "react";
import {
  Datagrid,
  Edit,
  List,
  NumberField,
  SimpleForm,
  TextInput,
  BooleanInput,
  FunctionField,
  ReferenceManyField,
  Create,
  ImageField,
  ImageInput,
} from "react-admin";
import PeopleIcon from "@material-ui/icons/People";
// Local
// import { useStyles } from "./Speakers.styles.js";
import { getFullSpeakerName } from "../utils";
const SpeakerEdit = (props) => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <ImageField source="photo_url" label="Photo" />
      <TextInput source="title" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <ImageField source="profile_image_file.path" label="Profile image" />
      <ImageInput
        source="FILE_photo"
        label="Photo Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <BooleanInput source="is_active" />
    </SimpleForm>
  </Edit>
);
const SpeakerCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="first_name" />
      <TextInput source="last_name" />
      <ImageInput
        source="FILE_photo"
        label="Photo Upload"
        accept="image/*"
        placeholder={<p>Click or drop a photo here.</p>}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <BooleanInput source="is_active" />
    </SimpleForm>
  </Create>
);
const LectureTotal = (props) => {
  return <div>{props.total}</div>;
};
const SpeakerList = (props) => (
  <List {...props} exporter={false}>
    <Datagrid rowClick="edit">
      <FunctionField
        label="Speaker"
        render={(record) => {
          return <span>{getFullSpeakerName(record)}</span>;
        }}
      />

      {/* https://stackoverflow.com/a/56009327/6591937 */}
      {/* setting perPage to 0 as we just need the counts */}
      <ReferenceManyField
        label="Shiurim"
        reference="lectures"
        target="speaker_id"
        perPage={0}
      >
        <LectureTotal />
      </ReferenceManyField>
      <ImageField source="profile_image_file.path" label="Profile image" />
      <NumberField source="is_active" />
    </Datagrid>
  </List>
);

export const Speakers = {
  name: "speakers",
  options: { label: "Speakers" },
  list: SpeakerList,
  icon: PeopleIcon,
  edit: SpeakerEdit,
  create: SpeakerCreate,
  roles: ["all"],
};
