import { makeStyles } from '@material-ui/core';

const flex = {
  display: 'flex',
  alignItems: 'center',
};

const flexStyles = {
  flexRow: {
    ...flex,
  },
  flexColumn: {
    ...flex,
    flexDirection: 'column',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  justifystart: {
    justifyContent: 'flex-start',
  },
};

export const chipStyles = {
  active: {
    backgroundColor: '#AFDD45',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  approved: {
    backgroundColor: '#AFDD45',
    color: '#33333',
    fontWeight: 600,
  },
  mltcSidebar: {
    backgroundColor: '#53B7E8',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  closed: {
    backgroundColor: '#829CB5',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  denial: {
    backgroundColor: '#E91F31',
    color: '#FFFFFF',
    fontWeight: 600,
  },
  discharged: {
    backgroundColor: '#F57C00',
    color: '#000000',
  },
  pending: {
    backgroundColor: '#FBD834',
    color: '#000000',
  },
};

export const styles = {
  ...flexStyles,
  ...chipStyles,
  cursorPointer: {
    cursor: 'pointer',
  },
};

export const useGlobalStyles = makeStyles(theme => ({
  ...styles,
}));

export const colors = {
  yellow: '#AEDD45',
  white: '#FFFFFF',
  black: 'rgba(0,0,0,0.87)',
};
