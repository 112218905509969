import { UIActions } from "../actions";

// import { customTypes } from '../actions/custom';
const DEFAULT_NET_SPEED = parseInt(process.env.REACT_APP_DEFAULT_NET_SPEED, 10);

const initialState = {
  mainTabIndex: 0,
  networkSpeed: DEFAULT_NET_SPEED || 3, // 1: Slow, 2: Fast, 3: Ultra Fast
  verifyContentHashDisabled: false
};

export function UIState(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case [UIActions.NETWORK_SPEED_CHANGE]:
      return {
        ...state,
        networkSpeed: payload
      };
    case UIActions.TAB_SELECT:
      return {
        ...state,
        mainTabIndex: payload
      };
    case [UIActions.VERIFY_CONTENT_HASH_TOGGLE]:
      return {
        ...state,
        verifyContentHashDisabled: !state.verifyContentHashDisabled
      };
    default:
      return state;
  }
}
