import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {
  adminReducer,
  adminSaga,
  // createAppReducer,
  USER_LOGOUT,
} from 'react-admin';
//import { reducers } from './state/reducers';
// import { UploaderState } from "./uploader/state/";
import { UIState, UploaderState, JoinerState, UserState, TrimmerState, FilesState, PublishingState } from "./state/reducers";
let store;

const adminStore = ({ authProvider, dataProvider, history }) => {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    ui: UIState,
    uploader: UploaderState,
    files: FilesState,
    joiner: JoinerState,
    publishing: PublishingState,
    trimmer: TrimmerState,
    user: UserState,
  });

  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        // add your own sagas here
      ].map(fork),
    );
  };
  const sagaMiddleware = createSagaMiddleware();

  store = createStore(
    resettableAppReducer,
    {
      /* set your initial state here */
    },
    compose(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        thunk,
        // add your own middlewares here
      ),
      typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f,
      // add your own enhancers here
    ),
  );
  sagaMiddleware.run(saga);
  return store;
};
export default adminStore;
export const reduxStore = () => store;
