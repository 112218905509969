import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#3F51B5"
    }
  },
  typography: {
    fontFamily: "Roboto, sans-serif"
  },
  overrides: {
    RaUserMenu: {
      userButton: {
        boxShadow: "none"
      }
    },
    RaLayout: {
      root: {
        background: "#fff"
      },
      contentWithSidebar: {
        alignItems: "flex-start",
        minHeight: "calc(100vh - 48px)"
      },
      content: {
        padding: "40px !important"
      }
    },
    RaSidebar: {
      root: {
        boxShadow:
          "0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)",
        position: "sticky",
        top: 48,
        minHeight: "calc(100vh - 48px)"
      }
    },
    MuiTab: {
      wrapper: {
        textTransform: "none",
        fontFamily: "Roboto",
        fontWeight: 500
      }
    }
  },
  // Do use the props directly
  props: {}
});
