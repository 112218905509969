import PeopleIcon from "@material-ui/icons/People";
import React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EmailField,
  Filter,
  List,
  NumberField,
  SelectInput,
  //ListGuesser,
  //EditGuesser,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  usePermissions
} from "react-admin";

export const Roles = [
  { id: "app_user", name: "App User" },
  { id: "org_admin", name: "Org Admin" },
  { id: "org_uploader", name: "Org Uploader" }
];

const ListFilters = (props: any) => (
  <Filter variant="outlined" {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="First name" source="first_name" alwaysOn />
    <TextInput label="Last name" source="last_name" alwaysOn />
    <TextInput label="Phone" source="phone" alwaysOn />
    <SelectInput source="role" choices={Roles} alwaysOn />
  </Filter>
);

const UserList = (props: any) => (
  <List filters={<ListFilters />} {...props} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <EmailField source="email" />
      <NumberField source="email_confirmed" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="phone" />
      <TextField source="zip" />
      <TextField source="role" />
      <NumberField source="login_attempts" />
      <DateField source="last_failed_login" />
    </Datagrid>
  </List>
);

const UserShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout {...props}>
      <EmailField source="email" />
      <BooleanField source="email_confirmed" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="phone" />
      <TextField source="role" />
      <TextField source="zip" />
      <NumberField source="login_attempts" />
      <DateField source="last_failed_login" />
    </SimpleShowLayout>
  </Show>
);

const UserEdit = (props: any) => (
  <Edit {...props}>
    <UserForm />
  </Edit>
);

const UserCreate = (props: any) => (
  <Create {...props}>
    <UserForm />
  </Create>
);

const UserForm = (props: any) => {
  const { permissions } = usePermissions();
  const choices = [
    { id: "org_admin", name: "Admin" },
    { id: "org_uploader", name: "Uploader" },
    { id: "app_user", name: "App User" }
  ];
  if (permissions === "super_admin") {
    choices.push({ id: "super_admin", name: "Super Admin" });
  }
  return (
    <SimpleForm {...props} variant="outlined">
      <TextInput required type="email" source="email" />
      <TextInput required source="first_name" />
      <TextInput required source="last_name" />
      <TextInput source="phone" />
      <TextInput source="zip" />
      <SelectInput source="role" choices={choices} />
      <BooleanInput source="email_confirmed" defaultValue={true} />
    </SimpleForm>
  );
};

export const Users = {
  name: "users",
  options: { label: "Users" },
  list: UserList,
  icon: PeopleIcon,
  edit: UserEdit,
  show: UserShow,
  create: UserCreate,
  roles: ["all"]
};
