import { jssPreset, StylesProvider, ThemeProvider } from "@material-ui/styles";
import { createHashHistory } from "history";
import { create } from "jss";
import React from "react";
import { Admin, Resource } from "react-admin";
import { Provider } from "react-redux";
import "./assets/css/styles.scss";
import { ErrorSentry } from "./components/common/ErrorSentry";
import { Layout } from "./components/layout";
import createAdminStore from "./createAdminStore";
import { theme } from "./design";
import i18nProvider from "./i18n";
import { Login } from "./pages";
import { customRoutes, resources } from "./resources";
import { jsonDataProvider } from "./server";
import { authProvider } from "./server/authProvider";
// import HandleTokenRefresh from './refresh-token';

const history = createHashHistory();
const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  // see https://material-ui.com/styles/advanced/#css-injection-order
  insertionPoint: "jss-insertion-point"
});

const MyLayout = (props: any) => {
  const { children, ...otherProps } = props;
  return <Layout {...otherProps}>{children}</Layout>;
};
/**
 * Admin props
 *  appLayout,
    authProvider,
    catchAll,
    children,
    customReducers,
    customRoutes = [],
    customSagas,
    dashboard,
    dataProvider,
    disableTelemetry,
    history,
    i18nProvider,
    initialState,
    layout,
    loading,
    locale,
    loginPage,
    logoutButton,
    menu, // deprecated, use a custom layout instead
    ready,
    theme,
    title = 'React Admin',

 */

function App() {
  return (
    <ErrorSentry>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <Provider
            store={createAdminStore({
              dataProvider: jsonDataProvider,
              authProvider,
              history
              // i18nProvider
            })}
          >
            <Admin
              layout={MyLayout}
              theme={theme}
              loginPage={Login}
              customRoutes={customRoutes}
              i18nProvider={i18nProvider}
              history={history}
              authProvider={authProvider}
              dataProvider={jsonDataProvider}
            >
              {resources}
              <Resource name="lectures" />
              <Resource name="speakers" />
              <Resource name="categories" />
              <Resource name="live_events" />
              <Resource name="locations" />
              <Resource name="banners" />
              <Resource name="sponsorships" />
              <Resource name="languages" />
              <Resource name="series_lectures" />
              {/* <HandleTokenRefresh />*/}
            </Admin>
          </Provider>
        </ThemeProvider>
      </StylesProvider>
    </ErrorSentry>
  );
}

export default App;
