import { Link } from "@material-ui/core";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FunctionField,
  List,
  SimpleForm,
  TextInput
} from "react-admin";

// Local
// import { useStyles } from "./LiveEvents.styles.js";
const LiveEventList = (props: any) => (
  <List {...props} exporter={false}>
    <Datagrid rowClick="edit">
      <FunctionField
        render={(record: any) => (
          <Link href={record.event_url} target="_blank">
            {record.event_title}
          </Link>
        )}
        label="Event"
      />
      <BooleanField source="active" label="Status" />
      <DateField source="event_start" label="Date" />
      <FunctionField
        render={(record: any) =>
          // TODO: may need to check if same day, and if not, to include day/date in the display
          [record.event_start, record.event_end]
            .filter(t => !!t)
            .map(t => new Date(t).toLocaleTimeString())
            .join(" - ")
        }
        label="Time"
      />
    </Datagrid>
  </List>
);

const LiveEventForm = (props: any) => (
  <SimpleForm {...props} variant="outlined">
    <TextInput source="event_url" fullWidth />
    <DateTimeInput source="event_start" />
    <DateTimeInput source="event_end" />
    <TextInput source="event_title" />
    <TextInput source="event_description" />
    <BooleanInput source="active" />
  </SimpleForm>
);

const LiveEventCreate = (props: any) => (
  <Create {...props} undoable={false}>
    <LiveEventForm />
  </Create>
);
const LiveEventEdit = (props: any) => (
  <Edit {...props} undoable={false}>
    <LiveEventForm />
  </Edit>
);

export const LiveEvents = {
  sort: 2,
  name: "live_events",
  options: { label: "Live Events" },
  icon: FiberManualRecordOutlinedIcon,
  list: LiveEventList,
  edit: LiveEventEdit,
  create: LiveEventCreate,
  roles: ["all"]
};
