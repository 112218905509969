const { REACT_APP_API_BASE_URL } = process.env;
const productionHost = "media.shulspace.org";
const stagingHost = "staging-media.shulspace.org";

const IS_PROD = window.location.hostname === productionHost;
const IS_STAGING = window.location.hostname === stagingHost;

const API_BASE_URL = IS_PROD
  ? "https://media.shulspace.org"
  : IS_STAGING
  ? "https://staging-media.shulspace.org"
  : REACT_APP_API_BASE_URL;
export { API_BASE_URL };
