import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { PieChart } from "react-minimal-pie-chart";

const useStyles = makeStyles(() => ({
  dashboardCard: {
    border: "1px solid #E4E7EB",
    boxSizing: "border-box",
    height: "100%",
    padding: "40px 32px"
  },
  iconCircle: {
    alignItems: "center",
    borderRadius: "100%",
    display: "flex",
    height: 44,
    justifyContent: "center",
    margin: "0 auto 24px",
    width: 44
  },
  dashboardCardAmount: {
    color: "#3A3B3F",
    fontWeight: 500,
    fontSize: 32,
    textAlign: "center"
  },
  dashboardCardTitle: {
    color: "#66788A",
    fontSize: 14,
    textAlign: "center"
  },
  dashboardCardDivider: {
    backgroundColor: "#E4E7EB",
    height: 1,
    width: "calc(100% - 16px)",
    margin: "40px 8px"
  },
  bottomDashboardCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginTop: 48,
    border: "1px solid #E4E7EB",
    boxSizing: "border-box",
    height: "100%",
    padding: 16
  },
  bottomDashboardCardDivider: {
    height: 84,
    width: 1,
    backgroundColor: "#e4e7eb"
  }
}));

const DashboardCard = (props: any) => {
  const { amount, icon, iconBackgroundColor, title, options } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.dashboardCard} elevation={0}>
      <div
        className={classes.iconCircle}
        style={{ background: iconBackgroundColor }}
      >
        {icon}
      </div>
      <Typography className={classes.dashboardCardAmount}>
        {amount.toLocaleString()}
      </Typography>
      <Typography className={classes.dashboardCardTitle}>{title}</Typography>
      <div className={classes.dashboardCardDivider} />
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 92, height: 92 }}>
          <PieChart
            data={options.map((option: any) => ({
              title: option.title,
              value: option.value,
              color: option.color
            }))}
            radius={50}
          />
        </div>
        <div style={{ marginLeft: 16 }}>
          {options.map((option: any) => (
            <div
              style={{ display: "flex", margin: "8px 0", alignItems: "center" }}
            >
              <div
                style={{
                  height: 16,
                  width: 16,
                  background: option.color,
                  marginRight: 8
                }}
              />
              <Typography
                style={{
                  fontWeight: 500,
                  color: "#66788A",
                  marginRight: 8,
                  fontSize: 14
                }}
              >
                {option.title}
              </Typography>
              <Typography style={{ color: "#3A3B3F", fontSize: 14 }}>
                {option.value.toLocaleString()}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </Paper>
  );
};

export const Dashboard = (props: any) => {
  const classes = useStyles();
  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <DashboardCard
            amount={24056}
            icon={<VisibilityOutlinedIcon style={{ color: "#fff" }} />}
            iconBackgroundColor="#FFDF50"
            title="Shiur views & downloads"
            options={[
              { title: "Views", value: 21987, color: "#3F51B5" },
              { title: "Downloads", value: 3321, color: "#FFDF50" }
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard
            amount={8563}
            icon={<PeopleOutlinedIcon style={{ color: "#fff" }} />}
            iconBackgroundColor="#0097FE"
            title="Users"
            options={[
              { title: "Male", value: 4987, color: "#0097FE" },
              { title: "Female", value: 3321, color: "#FAB8C4" }
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardCard
            amount={14900}
            icon={<CloudDownloadOutlinedIcon style={{ color: "#fff" }} />}
            iconBackgroundColor="#FAB8C4"
            title="App Downloads"
            options={[
              { title: "IOS", value: 6987, color: "#3F51B5" },
              { title: "Android", value: 4321, color: "#FFDF50" }
            ]}
          />
        </Grid>
      </Grid>
      <Paper className={classes.bottomDashboardCard} elevation={0}>
        <div
          style={{
            textAlign: "center",
            flex: 1
          }}
        >
          <Typography variant="h4">8,563</Typography>
          <Typography>Total shiurim</Typography>
        </div>
        <div className={classes.bottomDashboardCardDivider} />
        <div
          style={{
            textAlign: "center",
            flex: 1
          }}
        >
          <Typography variant="h4">8</Typography>
          <Typography>Speakers</Typography>
        </div>
      </Paper>
    </Box>
  );
};
