import React from "react";

import {
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextInput,
  TextField,
  FunctionField,
  ReferenceField,
  SelectInput,
  ReferenceInput,
  AutocompleteArrayInput,
  Create
} from "react-admin";
// Local
// import { useStyles } from "./Categories.styles.js";
import { getCategoryHierarchy } from "../utils";
import { authClient } from "../server";

const SeriesList = (props) => (
  <List {...props} exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="category_id" reference="categories">
        <FunctionField render={(record) => getCategoryHierarchy(record)} />
      </ReferenceField>
    </Datagrid>
  </List>
);

const SeriesCreate = (props) => {
  return (
    <Create {...props} undoable={false}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput
          source="category_id"
          reference="categories"
          perPage={-1}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
const SeriesEdit = (props) => {
  const [lectureOptions, setLectureOptions] = React.useState([]);
  React.useEffect(() => {
    async function fetchData() {
      const { json } = await authClient.get(
        `lectures?filter=%7B%7D&range=%5B0%2C-2%5D`
      );
      setLectureOptions(json.map((i) => ({ id: `${i.id}`, name: i.title })));
    }
    fetchData();
  }, []);
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput
          source="category_id"
          reference="categories"
          perPage={-1}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        {/* <ReferenceManyField
          label="Lectures"
          reference="series_lectures"
          target="series_id"
          // perPage={0}
        >
          <SingleFieldList>
            <ChipField source="lecture.title" />
          </SingleFieldList>
        </ReferenceManyField> */}
        {lectureOptions.length > 0 && (
          <AutocompleteArrayInput
            label="Lectures"
            source="lectureIds"
            choices={lectureOptions}
          />
        )}
      </SimpleForm>
    </Edit>
  );
};

export const Series = {
  name: "series",
  options: { label: "Series" },
  list: SeriesList,
  edit: SeriesEdit,
  create: SeriesCreate,
  roles: ["all"],
};
