import React, { useState } from "react";
import clsx from "clsx";
import { useAuthenticated, useNotify, Notification } from "react-admin";
import { TextField, Button, CssBaseline, Typography } from "@material-ui/core";
import { authClient } from "../server";
import { useStyles } from "./Login.styles";
import { Link } from "react-router-dom";

export const ForgotPassword = () => {
  useAuthenticated({ page: "forgot-password" });
  const notify = useNotify();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [view, setView] = useState("form");
  const handleChange = e => {
    const {
      target: { value }
    } = e;
    setEmail(value);
  };
  const submit = async e => {
    e.preventDefault();
    try {
      await authClient.post(
        "admin/users/forgot-password",
        { email },
        null,
        false
      );
    } catch (e) {
      console.error("reset password failed");
      console.error(e);
      notify("ra.notification.http_error", "warning");
      return;
    }
    setView("message");
  };
  return (
    <main className={classes.main}>
      <CssBaseline />
      {view === "form" ? (
        <div className={clsx(classes.paper, classes.paperForgotPassword)}>
          <Typography
            component="h1"
            className={clsx(classes.welcome, classes.welcomeForgotPass)}
            align="left"
          >
            Password Recovery
          </Typography>
          <Typography component="h2" className={classes.signIn}>
            Enter your email address and we will send you a link to reset your
            password
          </Typography>
          <form className={classes.form} noValidate onSubmit={submit}>
            <TextField
              margin="dense"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              autoComplete="email"
              autoFocus
              onChange={handleChange}
            />
            <div className={classes.forgotSubmitWrapper}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!email}
                size="large"
              >
                Send Email
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <div className={clsx(classes.paper, classes.paperInstructions)}>
          <Typography
            component="h1"
            className={clsx(classes.welcome, classes.welcomeInstructions)}
            align="centers"
          >
            Recovery sent to your E-mail
          </Typography>
          <Typography
            component="h2"
            className={clsx(classes.signIn, classes.instructionsMsg)}
            align="center"
          >
            Check your email adress and follow the instructions.
          </Typography>
          <Link to="login">Login</Link>
        </div>
      )}
      <Notification />
    </main>
  );
};
