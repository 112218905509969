import decodeJwt from "jwt-decode";
import { API_BASE_URL } from "../config";
interface AuthError {
  body: any;
  name: string;
  status: number;
  message: string;
  stack: string;
}
export const authProvider: any = {
  login: (props: any) => {
    const { email, password } = props;
    const request = new Request(API_BASE_URL + "/api/V1/auth/login", {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        const decodedToken: any = decodeJwt(token);
        localStorage.setItem("token", token);
        localStorage.setItem("permissions", decodedToken.roles);
      });
  },
  checkError: (error: AuthError) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject();
    }
    //other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: (params: any) => {
    if (params.page === "forgot-password") {
      return Promise.resolve();
    }
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, fullName, email, first_name, last_name } = JSON.parse(
        localStorage.getItem("auth")
      );
      return Promise.resolve({ id, fullName, email, first_name, last_name });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  }
};
