import { PublishingActions } from "../actions";

const initialState = {};

export function PublishingState(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case [PublishingActions.FILE_SUBMIT]:
      const { fileId, lectureId } = payload;
      return {
        ...state,
        fileId,
        lectureId,
      };
    default:
      return state;
  }
}
