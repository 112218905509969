import React from "react";
import { Layout as AdminLayout } from "react-admin";
import { useLocation } from "react-router-dom";
import AppBar from "./AppBar";
import Menu from "./Menu";

export const Layout = (props: any) => {
  const [showLayout, setShowLayout] = React.useState(true);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.includes("set-password")) {
      setShowLayout(false);
    } else {
      setShowLayout(true);
    }
  }, [location.pathname]);

  return (
    <>
      {showLayout ? (
        <AdminLayout {...props} appBar={AppBar} menu={Menu} />
      ) : (
        <div {...props} />
      )}
    </>
  );
};
