import formatBytes from "bytes";
import dprop from "dot-prop-immutable";
import { FileActions } from "../actions";
import { JoinerActions } from "../actions";
import { PublishingActions } from "../actions";
import { TrimmerActions } from "../actions";
import { UploaderActions } from "../actions";



const initialState = {
  /*
  [file.id]: file, ...
*/
};

// export const FilesState = {
//   name: "files",
//   persist: false,
//   defaults: {
//     /*
//     [file.id]: file, ...
//   */
//   },
//   handlers: {

//   },
// };



export function FilesState(state = initialState, action) {
  //Its possible to have payload set to null - we need to fix that
  let { type, payload } = action;
  if (!payload) {
    payload = {}
  }
  let { id, upload_progress, completing = false, size, error, part, seconds } = payload;
  let keyPrefix;
  let update;

  switch (type) {
    // FileActions
    case FileActions.FILE_ADD:
      return addFile(state, action.payload);
    case FileActions.FILE_CANCEL_DELETE:
      id = action.payload;
      return updateFile(state, id, {
        show_delete: undefined,
      });
    case FileActions.FILE_DELETE:
      return deleteFile(state, payload);
    case FileActions.FILE_LOAD:
      return addFile(state, action.payload);
    case FileActions.FILE_SHOW_DELETE:
      return updateFile(state, payload, {
        show_delete: true,
      });

    // JoinerActions
    case JoinerActions.JOIN_FILE_POLL:
      if (action.loading || action.payload.error) {
        return state;
      }
      action.payload.spinner = true;
      return addFile(state, action.payload);

    case JoinerActions.JOIN_FILE_DONE:
      return updateFile(state, id, {
        ...action.payload,
        spinner: false,
        network_failed: false,
        displaySize: formatBytes(size),
      });

    case JoinerActions.NETWORK_FAILED:
      return updateFile(state, id, {
        network_failed: true,
        err_message: error,
      });

    case JoinerActions.JOIN_FAILED:
      return updateFile(state, id, {
        err_message: error,
        join_failed: true,
        spinner: false,
      });

    // PublishingActions
    case PublishingActions.FILE_SUBMIT:
      const { fileId } = action.payload;
      return updateFile(state, fileId, {
        publishing_status: 1, // Submitting
      });

    case PublishingActions.FILE_SUBMIT_OK:
      return updateFile(state, fileId, {
        publishing_status: 2, // Published
      });

    // TrimmerActions
    case TrimmerActions.TRIM_FILE_MARK:
      const {
        [id]: { preview_end_duration },
      } = state;
      update = {
        [`trim_${part}`]: seconds,
      };
      if (part === "end") {
        // The amount of time being deducted from the end of the original media.
        update.trim_end_subtract = -(preview_end_duration - seconds);
      }
      return updateFile(state, id, update);

    case TrimmerActions.TRIM_FILE_PREVIEW_LOAD:
      return updateFile(state, id, {
        [`preview_${part}_url`]: "",
      });

    case TrimmerActions.TRIM_FILE_PREVIEW:
      const {
        duration,
        url,
        originalDuration,
      } = action.payload;
      keyPrefix = "preview_" + part + "_";
      return updateFile(
        state,
        id,
        {
          duration: originalDuration,
          [keyPrefix + "duration"]: duration,
          [keyPrefix + "seconds"]: seconds,
          [keyPrefix + "url"]: url,
        },
        file =>
          dprop.set(file, `previews.${part}_${seconds}`, {
            duration,
            url,
          }),
      );

    case TrimmerActions.TRIM_FILE_PREVIEW_CHANGE:
      keyPrefix = "preview_" + part + "_";
      return updateFile(state, id, {
        [keyPrefix + "seconds"]: seconds,
        [keyPrefix + "url"]: undefined,
      });

    case TrimmerActions.TRIM_FILE_RESET:
      return updateFile(state, id, {
        trim_begin: undefined,
        trim_end: undefined,
        trim_end_subtract: undefined,
      });

    case TrimmerActions.PREFETCHING:
      return updateFile(state, id, {
        prefetched: false,
        spinner: true,
      });

    case TrimmerActions.PREFETCHED:
      return updateFile(state, id, {
        prefetched: true,
        spinner: false,
      });

    // UploaderActions
    case UploaderActions.UPLOAD_CANCEL:
      id = action.payload;
      return deleteFile(state, id);

    case UploaderActions.PREFETCHING:
      return updateFile(state, id, {
        prefetched: false,
        spinner: true,
      });

    case UploaderActions.PREFETCH_DOWNLOAD_PROGRESS:
      const { downloadProgress = 0 } = action.payload;
      return updateFile(state, id, {
        downloadProgress,
      });

    case UploaderActions.PREFETCHED:
      return updateFile(state, id, {
        prefetched: true,
        spinner: false,
      });

    case UploaderActions.CORRUPT:
      const {
        response: { is_corrupt, err_message },
      } = action.payload;
      return updateFile(state, id, {
        is_corrupt,
        err_message,
      });

    case UploaderActions.UPLOAD_COMPLETE:
      update = action.payload.update;
      return updateFile(state, id, {
        upload_completed: true,
        upload_url: undefined,
        upload_headers: undefined,
        ...update,
      });

    case UploaderActions.UPLOAD_FAILED:
      id = action.payload;
      return updateFile(state, id, {
        upload_failed: true,
        upload_url: undefined,
        upload_headers: undefined,
      });

    case UploaderActions.UPLOAD_PROGRESS:
      return updateFile(state, id, {
        upload_progress: completing ? upload_progress : (parseFloat(upload_progress) * .95).toFixed(1) + " %",
      });

    case UploaderActions.UPLOAD_RESUME:
      id = action.payload;
      return updateFile(state, id, {
        multipart_last: undefined,
        multipart_missing: undefined,
        multipart_sizes: undefined,
        show_resume: undefined,
      });

    default:
      return state;
  }
}

/** @param {{id: string, size: number} file */
function addFile(state, file) {
  const { id, size } = file;
  return {
    ...state,
    [id]: {
      upload_progress: "",
      ...file,
      displaySize: formatBytes(size),
    },
  };
}

function deleteFile(state, id) {
  const { [id]: fileToDelete, ...filesThatAreNotBeingDeleted } = state;
  return filesThatAreNotBeingDeleted;
}

/** @param {object} state
 * @param {string} id
 * @prop {{[x:string]: object}} propsToSet
 */
function updateFile(state, id, propsToSet, mapFileState = file => file) {
  const { [id]: fileWithId, ...filesThatAreNotBeingUpdated } = state;
  return {
    ...filesThatAreNotBeingUpdated,
    [id]: mapFileState({
      ...fileWithId,
      ...propsToSet,
    }),
  };
}
