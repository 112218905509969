import { JoinerActions } from "../actions";
import { FileActions } from "../actions";
import { UploaderActions } from "../actions";

const initialState = {
  allowFileIds: [],
  filesToJoin: {},
  joiningToFileName: "",
  loading: false,
  sortedFileIds: [],
};

export function JoinerState(state = initialState, action) {
  let { type, payload } = action;
  if (!payload) {
    payload = {}
  }
  const { upload_completed, id, name } = payload;
  let { allowFileIds, sortedFileIds } = state;

  switch (type) {
    case JoinerActions.JOIN_FILE_CHANGE:
      return {
        ...state,
        joiningToFileName: action.payload,
      };
    case JoinerActions.JOIN_FILE_CREATE:
      if (action.loading) {
        return {
          ...state,
          error: undefined,
          loading: true,
        };
      }
      if (action.payload.error) {
        return {
          ...state,
          error: action.payload.error,
          loading: false,
        };
      }
      return {
        ...addFile(state, payload.id, payload.name),
        error: undefined,
        filesToJoin: {
          [payload.id]: true, // Causes the new file to be selected.
        },
        joiningToFileName: "",
        loading: false,
      };

    case JoinerActions.JOIN_FILE_POLL:
      if (action.loading || action.payload.error) {
        return state;
      }
      return {
        ...addFile(state, payload.id, payload.name),
        error: undefined,
        joiningToFileName: "",
        loading: false,
      };

    case JoinerActions.JOIN_FILE_DONE:
      // const { id } = action.payload;
      return {
        ...state,
        error: undefined,
        filesToJoin: {
          // Causes all join files to be unselected.
        },
      };

    case JoinerActions.JOIN_FILE_SELECT:
      const {
        filesToJoin: { [id]: wasSelected = false, ...otherFilesToJoin },
      } = state;
      if (wasSelected) {
        // Remove id from filesToJoin
        return {
          ...state,
          error: undefined,
          filesToJoin: otherFilesToJoin,
        };
      }
      // Add id to filesToJoin
      return {
        ...state,
        error: undefined,
        filesToJoin: {
          ...state.filesToJoin,
          [payload.id]: true,
        },
      };

    case JoinerActions.JOIN_FILE_SORT:
      sortedFileIds = action.payload;
      return {
        ...state,
        error: undefined,
        sortedFileIds,
      };

    case FileActions.FILE_ADD:
      if (!upload_completed) {
        return state;
      }
      return addFile(state, id, name);

    case FileActions.FILE_DELETE:
      let changed = false;
      let filesToJoin = state.filesToJoin;
      if (id in state.filesToJoin) {
        changed = true;
        filesToJoin = {
          ...state.filesToJoin,
        };
        delete filesToJoin[id];
      }
      /** @type {string[]} */
      let foundIndex = allowFileIds.indexOf(id);
      if (foundIndex > -1) {
        changed = true;
        allowFileIds = [
          ...allowFileIds.slice(0, foundIndex),
          ...allowFileIds.slice(foundIndex + 1),
        ];
        if (sortedFileIds) {
          foundIndex = sortedFileIds.indexOf(id);
          if (foundIndex > -1) {
            sortedFileIds = [
              ...sortedFileIds.slice(0, foundIndex),
              ...sortedFileIds.slice(foundIndex + 1),
            ];
          }
        }
      }
      if (!changed) {
        return state;
      }
      return {
        ...state,
        allowFileIds,
        filesToJoin,
        sortedFileIds,
      };

    case FileActions.FILE_LOAD:
      return addFile(state, id, name);

    case FileActions.FILES_LOAD_ALL:
      return {
        ...state,
        error: undefined,
        loading: action.payload,
      };

    case UploaderActions.UPLOAD_COMPLETE:
      return addFile(state, id, name);

    default:
      return state;
  }
}

function addFile(state, id, name) {
  if (!name.toLowerCase().endsWith(".mp4")) {
    return state;
  }
  const { allowFileIds, sortedFileIds } = state;
  if (allowFileIds.indexOf(id) > -1) {
    return state;
  }
  if (sortedFileIds.length > 0) {
    return {
      ...state,
      allowFileIds: allowFileIds.concat(id),
      sortedFileIds: sortedFileIds.concat(id),
    };
  }
  return {
    ...state,
    allowFileIds: allowFileIds.concat(id),
  };
}
