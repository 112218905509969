import React from "react";
import {
  TextField,
  Button,
  Snackbar,
  SnackbarContent,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export class UrlUploader extends React.PureComponent {
  state = {
    openSnack: false,
    snackMsg: "",
    snackBackColor: "red",
    videoFetchUrl: "",
    videoName: "",
    inputErrors: {}
  };

  checkIsValid = e => {
    if (!e.target.checkValidity()) {
      const inputErrors = this.state.inputErrors;
      inputErrors[e.target.name] = true;
      this.setState({
        openSnack: true,
        inputErrors,
        snackMsg: "Please fix errors below.",
        snackBackColor: "red"
      });
    } else {
      const inputErrors = this.state.inputErrors;
      inputErrors[e.target.name] = false;
      this.setState({ openSnack: false, inputErrors });
    }
  };

  inputHasErrors = () => {
    const inp = this.state.inputErrors;
    if (Object.keys(inp).length === 0) {
      return false;
      //check if any input errors are true
    } else if (
      Object.keys(inp).filter(inpName => inp[inpName] === true).length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };
  processUpload = async e => {
    if (!this.inputHasErrors()) {
      const response = await this.props.actions.uploadUrl({
        url: this.state.videoFetchUrl.trim(),
        name: this.state.videoName.trim()
      });
      if (response && response.message) {
        this.setState({
          openSnack: true,
          snackMsg: response.message,
          snackBackColor: "red"
        });
      } else if (response && response.success) {
        this.setState({
          openSnack: true,
          snackMsg:
            "Please proceed to Upload Media Tab in 15 minutes and refresh. ",
          snackBackColor: "green",
          videoFetchUrl: "",
          videoName: ""
        });
      } else {
        this.setState({
          openSnack: true,
          snackMsg: "Oops something went wrong.",
          snackBackColor: "red"
        });
      }
    } else {
      this.setState({ openSnack: true, snackBackColor: "red" });
    }
  };

  render() {
    let {
      state: { inputErrors, videoName, videoFetchUrl, snackBackColor }
    } = this;

    return (
      <section style={{ padding: "25px 15px" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          open={this.state.openSnack}
          autoHideDuration={6000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
        >
          <SnackbarContent
            style={{ backgroundColor: snackBackColor }}
            message={<span id="message-id">{this.state.snackMsg}</span>}
            action={[
              <IconButton
                style={{
                  backgroundColor: "white",
                  color: "black",
                  margin: "5px",
                  padding: "5px"
                }}
                key="close"
                aria-label="Close"
                onClick={() => this.setState({ openSnack: false })}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Snackbar>
        <TextField
          style={{ marginTop: "15px" }}
          label="Please enter lecture name (including extension)."
          type="text"
          name="lecture_name"
          error={!!inputErrors["lecture_name"]}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          value={videoName}
          onChange={e => this.setState({ videoName: e.target.value })}
          onBlur={this.checkIsValid}
          required
        />
        <TextField
          style={{ marginTop: "15px" }}
          label="Please enter valid public URL of lecture"
          error={!!inputErrors["lecture_url"]}
          name="lecture_url"
          type="url"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          value={videoFetchUrl}
          onChange={e => this.setState({ videoFetchUrl: e.target.value })}
          onBlur={this.checkIsValid}
          required
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          style={{ marginTop: "25px" }}
          onClick={this.processUpload}
        >
          Process
        </Button>
      </section>
    );
  }
}
