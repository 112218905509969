import MD5 from "js-md5";

export const shouldTestUploadError =
  process.env.REACT_APP_TEST_UPLOAD_ERROR === "true";

function detectMSEdgeLessThan1817763() {
  const ua = window.navigator.userAgent;
  const i = ua.indexOf("Edge/");
  if (i > -1) {
    const browserAndVersion = ua.substr(i);
    const version = browserAndVersion.split("/")[1];
    if (version) {
      if (parseFloat(version) < 18.17763) {
        return true;
      }
    }
  }
  return false;
}
const isMSEdgeLessThan1817763 = detectMSEdgeLessThan1817763();

/** Sends fake progress to MS-EDGE browser due to bug:
 * XMLHttpRequest onprogress not fired for streaming response
 * - https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/12224510/
 * - https://www.devexpress.com/Support/Center/Question/Details/T592619/fileuploader-doesn-t-display-progress-while-a-file-is-uploaded-in-edge
 */
function fakeFileProgressForMSEdge() {
  let loaded = 0;
  function onHandlerNotCalled(handleFileProgress) {
    if (loaded <= 25) {
      loaded += 0.35;
    } else if (loaded <= 50) {
      loaded += 0.2;
    } else if (loaded <= 90) {
      loaded += 0.05;
    } else if (loaded < 99) {
      loaded += 0.001;
    } else if (loaded < 99.999) {
      loaded += 0.0001;
    }
    return handleFileProgress({
      lengthComputable: loaded > 0,
      loaded,
      total: 100,
    });
  }
  return onHandlerNotCalled;
}
/** Returns an MD5 hash for the given file or blob in base64 format.
 * @param {Blob} fileOrBlob A file is a blob, so either one is fine.
 * @returns {Promise<string>} An MD5 hash in base64 format.
 */
export function getContentHash(fileOrBlob) {
  return new Promise(resolve => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(fileOrBlob);
    reader.onloadend = () => {
      resolve(MD5.base64(reader.result));
    };
  });
}
/** @param {(e:ProgressEvent,progress:number)=> void} handler */
export function handleFileProgress(handler) {
  /** @param {ProgressEvent} e */
  function doHandleFileProgress(e) {
    let progress;
    if (e.lengthComputable) {
      progress = (e.loaded / e.total) * 100.0;
    } else {
      // Handle lengthComputable false. Googles example:
      // https://developer.chrome.com/native-client/devguide/coding/progress-events#displaying-load-status
      progress = 0.0;
    }
    // Round to nearest 2 decimal places.
    progress = Math.round(progress * 100) / 100;
    e.progress = progress;
    handler(e, `${progress}%`);
  }
  if (isMSEdgeLessThan1817763) {
    return handlerWatchdog(
      750,
      doHandleFileProgress,
      fakeFileProgressForMSEdge(),
    );
  }
  return doHandleFileProgress;
}
/** Returns a wrapper for the given `handler` and sets a timer to call
 * `onHandlerNotCalled` when the `handler` wrapper isn't called in time.
 * @param {number} timeout Milliseconds timeout for `setTimeout()`.
 * @param {(...args) => void} handler The target handler.
 * @param {(...args) => (boolean|void)} onHandlerNotCalled The alternate handler to
 * call when `handler` wasn't called.
 */
function handlerWatchdog(timeout, handler, onHandlerNotCalled) {
  let called = false;
  let timer;
  function callHandler(...args) {
    called = true;
    return handler(...args);
  }
  function stopWatchdog() {
    if (timer) {
      clearTimeout(timer);
    }
  }
  callHandler.stopWatchdog = stopWatchdog;
  function checkIfHandlerWasCalled() {
    if (!called) {
      if (onHandlerNotCalled(handler) === false) {
        stopWatchdog();
        return;
      }
    }
    // Reset the condition and check again after another timeout...
    called = false;
    timer = setTimeout(checkIfHandlerWasCalled, timeout);
  }
  timer = setTimeout(checkIfHandlerWasCalled, timeout);
  return callHandler;
}
/** Replaces just the hostname of the given `url` with `fallbackURL`.
 * @param {string} url
 * @param {string} fallbackURL
 */
export function replaceHostWithFallbackURL(url, fallbackURL) {
  const thirdSlashIndex = url.indexOf("/", 8); // search past 'https://'
  const addForSlash = fallbackURL.endsWith("/") ? 1 : 0;
  return fallbackURL + url.substr(thirdSlashIndex + addForSlash);
}
