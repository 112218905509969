import { FileActions } from "../actions";
import { TrimmerActions } from "../actions";
import { UIActions } from "../actions";
import { PublishingActions } from "../actions";

const initialState = {};

export function TrimmerState(state = initialState, action) {
  let { type, payload } = action;
  if (!payload) {
    payload = {}
  }
  const { error, part } = payload;

  switch (type) {
    case FileActions.FILE_DELETE:
      if (state.fileToTrim !== action.payload) {
        return state;
      }
      return {
        ...state,
        fileToTrim: "",
        markersInvalid: false,
        preview_begin: false,
        preview_end: false
      };
    case FileActions.FILES_LOAD_ALL:
      return {
        ...state,
        loading: action.payload,
        markersInvalid: false,
        preview_begin: false,
        preview_end: false
      };
    case PublishingActions.FILE_SUBMIT:
      if (state.fileToTrim !== action.payload.fileId) {
        return state;
      }
      // The selected file is being submitted. Unselect it now.
      return {
        ...state,
        fileToTrim: "",
        markersInvalid: false,
        preview_begin: false,
        preview_end: false
      };
    case TrimmerActions.TRIM_FILE_MARK:
      return {
        ...state,
        markersInvalid: false
      };
    case TrimmerActions.TRIM_FILE_MARK_INVALID:
      return {
        ...state,
        markersInvalid: true
      };
    case TrimmerActions.TRIM_FILE_PREVIEW:
      return {
        ...state,
        [`preview_${part}_error`]: error
      };
    case TrimmerActions.TRIM_FILE_PREVIEW_CHANGE:
      return {
        ...state,
        [`preview_${part}`]: false,
        [`preview_${part}_error`]: undefined
      };
    case TrimmerActions.TRIM_FILE_PREVIEW_LOAD:
      return {
        ...state,
        [`preview_${part}`]: true,
        [`preview_${part}_error`]: undefined
      };
    case TrimmerActions.TRIM_FILE_SELECT:
      const fileToTrim =
        state.fileToTrim === action.payload ? "" : action.payload;
      return {
        ...state,
        fileToTrim,
        markersInvalid: false,
        preview_begin: false,
        preview_end: false
      };
    case UIActions.TAB_SELECT:
      if (action.payload === 1) {
        return state;
      }
      return {
        ...state,
        markersInvalid: false,
        preview_begin: false,
        preview_end: false
      };
    default:
      return state;
  }
}
