import React, { useState } from "react";
import get from "lodash/get";
import { useLogin, useRedirect, useNotify, Notification } from "react-admin";
import packageJson from "../../package.json";
import {
  TextField,
  Button,
  CssBaseline,
  Typography,
  Grid,
} from "@material-ui/core";
import { useStyles } from "./Login.styles";

export const Login = () => {
  const login = useLogin();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = useStyles();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  };
  const submit = (e) => {
    e.preventDefault();
    login(state).catch((error) => {
      const status = get(error, "status", "");
      if (status === 429) {
        notify(
          "Too many failed login attempts. Try again later or reset your password."
        );
      } else {
        notify("Invalid email or password");
      }
    });
  };
  const { email, password } = state;
  const { version } = packageJson;

  return (
    <main className={classes.main}>
      <CssBaseline />
      <span className={classes.version}>{version}</span>
      <div className={classes.paper}>
        <img
          src="https://www.shulspace.com/wp-content/uploads/2020/10/Shulspace-logo.png"
          alt="logo"
          className={classes.logo}
        />
        <Typography component="h1" className={classes.welcome}>
          Welcome
        </Typography>
        <Typography component="h2" className={classes.signIn}>
          Sign in to continue
        </Typography>
        <form className={classes.form} noValidate onSubmit={submit}>
          <TextField
            margin="dense"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={email}
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="password"
            label="Password"
            value={password}
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
          />
          <Grid container style={{ marginTop: 15 }}>
            <Grid item xs>
              <Typography
                variant="body2"
                style={{ cursor: "pointer" }}
                onClick={() => redirect("/forgot-password")}
                color="primary"
              >
                Forgot password?
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.submitWrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!email || !password}
              size="large"
            >
              Sign In
            </Button>
          </div>
        </form>
      </div>
      <Notification />
    </main>
  );
};
