import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { UploaderActions } from "../../state/actions";
import { UrlUploader as View } from "./UrlUploader";

export const UrlUploader = connect(
  function mapState(state) {
    const {
      files,
      uploader: { loading, selectedUpload },
      ui: { networkSpeed, verifyContentHashDisabled },
      user: {
        role: { administrator: userIsAdmin = false },
      },
    } = state;
    return {
      files,
      loading,
      networkSpeed,
      selectedUpload,
      userIsAdmin,
      verifyContentHashDisabled,
    };
  },
  function mapDispatch(dispatch) {
    return {
      actions: {
        ...bindActionCreators(UploaderActions, dispatch),
      },
    };
  },
)(View);
