import React from "react";
import {
  // BulkDeleteButton,
  Button,
  refreshView,
  setListSelectedIds,
  showNotification
} from "react-admin";
import { connect } from "react-redux";
import { authClient } from "../server";

export const PublishingStatuses = [
  { id: 500, name: "error" },
  { id: 1, name: "submitting" },
  { id: 2, name: "queued" },
  { id: 3, name: "rendering" },
  { id: 4, name: "encoding" },
  { id: 5, name: "finished" }
];

class ArchiveButtonView extends React.PureComponent {
  onClick = async () => {
    if (!window.confirm("Archive the selected uploads?")) {
      return;
    }
    const {
      refreshView,
      resource,
      selectedIds,
      setListSelectedIds,
      showNotification
    } = this.props;
    // console.log("ARCHIVING: ", { resource, selectedIds });
    // TODO: Warn the user if any items didn't get sent.
    const results = await authClient
      .post("/uploads/send-to-log", {
        ids: selectedIds
      })
      .catch(err => {
        console.error(err);
        showNotification("uploads.archived", "warning");
      });
    if (results) {
      // console.log('ARCHIVED: ', results);
      showNotification("uploads.archived");
      setListSelectedIds(resource, []);
      refreshView();
    }
  };
  render() {
    return <Button label="Archive" onClick={this.onClick} />;
  }
}
const ArchiveButton = connect(undefined, {
  refreshView,
  setListSelectedIds,
  showNotification
})(ArchiveButtonView);

class VimeoDownloadButtonView extends React.PureComponent {
  onClick = async () => {
    if (!window.confirm("ReAttempt downloading the selected uploads?")) {
      return;
    }
    const {
      refreshView,
      resource,
      selectedIds,
      setListSelectedIds,
      showNotification
    } = this.props;
    // console.log("ARCHIVING: ", { resource, selectedIds });
    // TODO: Warn the user if any items didn't get sent.
    const results = await authClient
      .post("/uploads/reattempt-vimeo-download", {
        ids: selectedIds
      })
      .catch(err => {
        console.error(err);
        showNotification(
          err?.body?.message || "Something Went Wrong",
          "warning"
        );
      });
    if (results) {
      // console.log('ARCHIVED: ', results);
      showNotification("Vimeo-Download Successful");
      setListSelectedIds(resource, []);
      refreshView();
    }
  };
  render() {
    return <Button label="Vimeo-Download" onClick={this.onClick} />;
  }
}

const VimeoDownloadButton = connect(undefined, {
  refreshView,
  setListSelectedIds,
  showNotification
})(VimeoDownloadButtonView);

class RepublishButtonView extends React.PureComponent {
  onClick = async () => {
    if (!window.confirm("Republish the selected uploads?")) {
      return;
    }
    const {
      resource,
      selectedIds,
      setListSelectedIds,
      showNotification
    } = this.props;
    // console.log("REPUBLISHING: ", { resource, selectedIds });
    // TODO: Warn the user if any items didn't get republished.
    const results = await authClient
      .post("/uploads/republish", {
        ids: selectedIds
      })
      .catch(err => {
        console.error(err);
        showNotification("uploads.republished", "warning");
      });
    if (results) {
      // console.log('REPUBLISHED: ', results);
      showNotification("uploads.republished");
      setListSelectedIds(resource, []);
      refreshView();
    }
  };
  render() {
    return <Button label="Republish" onClick={this.onClick} />;
  }
}
const RepublishButton = connect(undefined, {
  setListSelectedIds,
  showNotification
})(RepublishButtonView);

export function BulkActionsView(props) {
  const { resource } = props;
  return (
    <React.Fragment>
      {/* {resource === "uploads-rendering" && <ArchiveButton {...props} />}
      {resource === "uploads-rendering" && <VimeoDownloadButton {...props} />} */}
      <RepublishButton {...props} />
      {/* <BulkDeleteButton {...props} /> */}
    </React.Fragment>
  );
}
