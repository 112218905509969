import jwtDecode from "jwt-decode";
import { stringify } from "query-string";
import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  fetchUtils,
  Resource,
  usePermissions
} from "react-admin";
import uuidv4 from "uuid/v4";
import { API_BASE_URL } from "../config";
import { authRoles } from "./authRoles";
//TODO: Add when we setup redis for blacklisting
// import { authDisconnect } from "./socketIO";

export { authRoles };

const { NODE_ENV, REACT_APP_MOCK_ROLES } = process.env;

const __DEV__ = NODE_ENV === "development";

export const baseURL = API_BASE_URL.endsWith("/")
  ? API_BASE_URL.substr(0, API_BASE_URL.length - 1)
  : API_BASE_URL;

/** Authorized user info. */
export const authUser: any = {
  deviceId: "",
  id: 0,
  loggedIn: false,
  roles: [],
  isSuperAdmin: false,
  isOrgAdmin: false,
  /**
   * Loads `authUser` from the given token, if any, and returns a success bool.
   * @param {string} [token] Optional token, otherwise loaded from localStorage.
   */
  load(token?: string | null) {
    try {
      if (!token) {
        token = authToken();
      }
      if (!token) {
        return false;
      }
      /** @type {AuthTokenInfo} */
      const tokenInfo: any = jwtDecode(token);
      if (tokenInfo) {
        const roles =
          __DEV__ && REACT_APP_MOCK_ROLES
            ? REACT_APP_MOCK_ROLES.split(",").map(role => role.trim())
            : tokenInfo.roles;
        authUser.id = tokenInfo.userId;
        authUser.loggedIn = true;
        authUser.roles = roles;
        authRoles.forEach(ar => {
          authUser[ar.prop] = roles.indexOf(ar.id) > -1;
        });
        authUser.deviceId = getOrCreateDeviceUUID();
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  },
  onLogin(handler: any) {
    authEventSubscribers.add("login", handler);
    return () => {
      authEventSubscribers.remove("login", handler);
    };
  },
  onLogout(handler: any) {
    authEventSubscribers.add("logout", handler);
    return () => {
      authEventSubscribers.remove("logout", handler);
    };
  },
  /**
   * Resets all `authUser` props.
   */
  reset() {
    authUser.id = 0;
    authUser.loggedIn = false;
    authUser.roles = [];
    authRoles.forEach(ar => (authUser[ar.prop] = false));
    authUser.deviceId = "";
  }
};

/** @param {string} relativeURL */
export function apiURL(relativeURL: any) {
  if (relativeURL.startsWith("/")) {
    return baseURL + relativeURL;
  }
  return baseURL + "/" + relativeURL;
}

/** An axios compatible fetch client using `authFetchJson`. */
export const authClient = {
  /** HTTP Get
   * @param {string} url Relative url to an API endpoint.
   * @param {RequestInit} [options] Request options.
   */
  get(url: string, options: Record<string, any> | undefined, isAdmin = true) {
    return authFetchJson(url, { method: "GET", ...options }, isAdmin);
  },
  /** HTTP Delete
   * @param {string} url Relative url to an API endpoint.
   * @param {RequestInit} [options] Request options.
   */
  delete(
    url: string,
    options: Record<string, any> | undefined,
    isAdmin = true
  ) {
    return authFetchJson(url, { method: "DELETE", ...options }, isAdmin);
  },
  /** HTTP Post
   * @param {string} url Relative url to an API endpoint.
   * @param {BodyInit} [data] Request options.
   * @param {RequestInit} [options]
   */
  post(
    url: string,
    // data: {
    //   upload_time?: number;
    //   ids?: string[];
    //   name?: string;
    //   part?: "begin" | "end";
    //   seconds?: number;
    //   zone?: string;
    //   size?: number;
    //   mime_type?: string;
    //   direction?: "from" | "to";
    //   userId?: string | number;
    //   email?: string;
    //   password?: string;
    //   confirm_password?: string;
    //   token?: any;
    // },
    data: any,
    options: Record<string, any> | null | undefined,
    isAdmin = true
  ) {
    return authFetchJson(
      url,
      {
        method: "POST",
        body: JSON.stringify(data),
        ...options
      },
      isAdmin
    );
  },
  /** HTTP Put
   * @param {string} url Relative url to an API endpoint.
   * @param {BodyInit} [data] Request options.
   * @param {RequestInit} [options]
   */
  put(
    url: string,
    data: any,
    options: Record<string, any> | undefined,
    isAdmin = true
  ) {
    return authFetchJson(
      url,
      {
        method: "PUT",
        body: JSON.stringify(data),
        ...options
      },
      isAdmin
    );
  },
  putUnstringifiedData(
    url: string,
    data: any,
    options: Record<string, any> | undefined,
    isAdmin = true
  ) {
    return authFetchJson(
      url,
      {
        method: "PUT",
        body: data,
        ...options
      },
      isAdmin
    );
  }
};

const authEventSubscribers: any = {
  login: [],
  logout: [],

  add(type: string, sub: any) {
    const { [type]: subscribers } = authEventSubscribers;
    subscribers.push(sub);
  },
  notify(type: string, ...args: undefined[]) {
    const { [type]: subscribers } = authEventSubscribers;
    subscribers.forEach((sub: (arg0: any) => any) => sub({ ...args }));
  },
  remove(type: string, sub: any) {
    const { [type]: subscribers } = authEventSubscribers;
    authEventSubscribers[type] = subscribers.filter(
      (item: any) => item !== sub
    );
  }
};
/** Adds an authorization header to react-admins `fetchUtils.fetchJson`.
 * @param {string} url Relative url to an API endpoint.
 * @param {RequestInit} [options] Request options.
 * @returns {Promise<FetchJsonResponse>}
 */
export function authFetchJson(
  resource: string,
  options?: Record<string, any>,
  isAdmin?: boolean
) {
  if (!options) {
    options = {};
  }
  const token = authToken();
  if (token) {
    const bearerToken = `Bearer ${token}`;
    if (options.headers) {
      options.headers.set("Authorization", bearerToken);
    } else {
      options.headers = new Headers({
        Accept: "application/json",
        Authorization: bearerToken
      });
    }
  }
  const url = `${API_BASE_URL}/api/V1/${
    isAdmin === false ? "" : "admin/"
  }${resource}`;
  return fetchUtils.fetchJson(url, options);
}
/** Handlers for different types of react-admin AUTH actions.
 * @type {{[action:string]: (params:object)=> Promise<any>}}
 */
const authHandlers: any = {
  [AUTH_CHECK](params: any) {
    if (!authUser.loggedIn && !authUser.load()) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  [AUTH_ERROR](params: { status: any }) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("jwtExpiry");
      authUser.reset();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  /** Function to provide user permissions when rendering resources with
   * `<Admin>{(permissions) => [<Resource ... />]}</Admin>`.
   * See https://marmelab.com/react-admin/Authorization.html
   * See App.renderResources where the result of this function is passed.
   */
  [AUTH_GET_PERMISSIONS](params: any) {
    if (!authUser.loggedIn) {
      return Promise.reject();
    }
    return Promise.resolve(authUser.roles);
  },
  [AUTH_LOGIN](params: { email: any; password: any }) {
    const { email, password } = params;
    const request = new Request(API_BASE_URL + "/api/V1/auth/login", {
      method: "POST",
      body: JSON.stringify({
        email,
        password
      }),
      headers: new Headers({
        "Content-Type": "application/json"
      })
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token, expiration }) => {
        localStorage.setItem("token", token);
        localStorage.setItem("jwtExpiry", expiration);
        authUser.load(token);
        authEventSubscribers.notify("login");
        // NOTE: Subscribing to the login auth event may work for some cases,
        // but completely reloading the page after a login is the safest way
        // to ensure that the application uses current roles and permissions...
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  },
  [AUTH_LOGOUT](params: any) {
    localStorage.removeItem("token");
    localStorage.removeItem("jwtExpiry");
    authUser.reset();
    authEventSubscribers.notify("logout");
    //authDisconnect();
    return Promise.resolve();
  }
};
/** React-admin authorization provider */
export function authProvider(type: string | number, params: any) {
  const handler = authHandlers[type];
  if (handler) {
    return handler(params);
  }
  return Promise.reject("Unknown method");
}
/** Returns `true` if the user is a full `administrator` or has one of the
 * given roles.
 * @param {string[]} [roles] The roles to check for.
 * @param {{[role:string]:string[]}} [permissions] Optional permissions by role.
 * @param {"create"|"edit"|"list"|"show"} [permission] Permission to check for.
 */
export function authorized(
  roles: string | string[],
  permissions: string,
  permission: string
) {
  if (!permissions) return false;
  //const permissionArray = permissions?.split(",");
  if (
    roles.includes("all") ||
    permission?.includes("super_admin")
    //permissionArray.some((role: any) => roles?.includes(role))
  ) {
    return true;
  }
  return false;
}
/** Returns the auth token, if any. */
export function authToken() {
  return localStorage.getItem("token");
}

const DATA_URL_PREFIX_ENDING = "base64,";
const DATA_URL_PREFIX_ENDING_LENGTH = DATA_URL_PREFIX_ENDING.length;

/** Converts an inputted `File` to a data url string.
 * @param {File} file
 * @returns {Promise<string | ArrayBuffer>}
 */
export function convertFileToDataURL(file: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}
/** Converts an inputted `File` to a base64 string.
 * @param {File} file
 * @returns {Promise<string | ArrayBuffer>}
 */
export async function convertFileToBase64(file: any) {
  const dataURL = await convertFileToDataURL(file);
  const base64 =
    typeof dataURL === "string"
      ? dataURL.substr(
          dataURL.indexOf(DATA_URL_PREFIX_ENDING) +
            DATA_URL_PREFIX_ENDING_LENGTH
        )
      : "";
  // console.log(`Converted file ${file.name} to base64:`, base64);
  return base64;
}
/**
 * Converts the given data objects `FILE_*` keys (if any) to upload.
 */
export async function convertFilesToUpload(data: { [x: string]: any }) {
  let dataOut;
  const keys = Object.keys(data);
  const keysLen = keys.length;
  for (let i = 0; i < keysLen; i++) {
    const key = keys[i];
    // Only process `params.data` fields that hold files.
    if (!key.startsWith("FILE_")) {
      continue;
    }
    // Initialize `dataOut` and prepare to convert the `fileProp` field.
    if (!dataOut) {
      dataOut = {
        ...data
      };
    }
    const fileProp = data[key];
    if (!fileProp) {
      // Cleanup and skip.
      delete dataOut[key];
      continue;
    }
    if (Array.isArray(fileProp)) {
      // Multiple files field.
      const converted = await convertMultipleFilesToUpload(fileProp);
      if (converted.length < 1) {
        delete dataOut[key];
      } else {
        dataOut[key] = converted;
      }
    } else {
      // Single file field.
      if (!fileProp.rawFile) {
        // Cleanup and skip.
        delete dataOut[key];
      } else {
        dataOut[key] = await convertFileToUpload(fileProp);
      }
    }
  }
  return dataOut || data;
}
/**
 * Converts a single file prop for upload.
 * @param {{rawFile:File}} fileProp
 */
async function convertFileToUpload(fileProp: { rawFile: any }) {
  /** @type {File} */
  const rawFile = fileProp.rawFile;
  const base64 = await convertFileToBase64(rawFile);
  return {
    name: rawFile.name,
    size: rawFile.size,
    type: rawFile.type,
    data: base64
  };
}
/**
 * Converts an array of file props for upload.
 * @param {{rawFile:File}[]} filesProp
 */
async function convertMultipleFilesToUpload(filesProp: any[]) {
  const { length } = filesProp;
  /** @type {{name:string,size:number,type:string,data:string}[]} */
  const converted = [];
  for (let i = 0; i < length; i++) {
    const fileProp = filesProp[i];
    /** @type {File} */
    const rawFile = fileProp.rawFile;
    if (!rawFile) {
      continue;
    }
    const base64 = await convertFileToBase64(rawFile);
    converted.push({
      name: rawFile.name,
      size: rawFile.size,
      type: rawFile.type,
      data: base64
    });
  }
  return converted;
}
/** Gets or creates a local device UUID from localStorage. */
function getOrCreateDeviceUUID() {
  const KEY = "device_uuid";
  let deviceId = localStorage.getItem(KEY) || "";
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem(KEY, deviceId);
  }
  return deviceId;
}
/**
 * Custom data action handlers by resource and action type.
 * @type {{[resource:string]:{[action:string]:DataActionHandler}}}
 */
const dataActionHandlers: any = {};
/**
 * Register a custom action handler.
 * @param {string} resource Name of the resource.
 * @param {DataActions} type
 * @param {DataActionHandler} handler
 */
export function onDataAction(resource: any, type: any, handler: any) {
  dataActionHandlers[resource] = {
    ...dataActionHandlers[resource],
    [type]: handler
  };
  return function removeDataActionHandler() {
    const { [type]: _removed, ...rest } = dataActionHandlers[resource];
    dataActionHandlers[resource] = rest;
  };
}
/**
 * Custom JSON data provider. Wraps `ra-data-json-server` with additional
 * functionality such as `File` uploads.
 */
//export async function jsonDataProvider(type, resource, params) {

// Convert file params.
// switch (type) {
//   case "CREATE":
//   case "UPDATE":
//     params.data = await convertFilesToUpload(params.data);
//     break;
//   default:
//     break;
// }
// // Run a custom action handler.
// const handlers = dataActionHandlers[resource];
// if (handlers) {
//   const actionHandler = handlers[type];
//   if (actionHandler) {
//     let submitted = false;
//     const submit = (...args) => {
//       submitted = true;
//       return jsonRequest(...args);
//     };
//     const result = actionHandler(type, resource, params, submit);
//     if (submitted) {
//       // Handler called submit. Done.
//       return result;
//     } else if (result instanceof Promise) {
//       // Handler didn't call submit. Continue below.
//       await result;
//     }
//   }
// }

// return jsonRequest(type, resource, params);

export const jsonDataProvider: any = {
  getList: (resource: any, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter)
    };
    const url = `${resource}?${stringify(query)}`;

    return authFetchJson(url).then(({ headers, json }) => ({
      data: json,
      total: +headers.get("x-total-count") || 0
    }));
  },
  getOne: (resource: string, params: Record<string, any>) =>
    authFetchJson(`${resource}/${params.id}`).then(({ json }) => ({
      data: json
    })),

  getMany: (resource: string, params: Record<string, any>) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    const url = `${resource}?${stringify(query)}`;
    return authFetchJson(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource: string, params: Record<string, any>) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id
      })
    };
    const url = `${resource}?${stringify(query)}`;

    return authFetchJson(url).then(({ headers, json }): {
      data: any;
      total: any;
    } => ({
      data: json,
      total: +headers.get("X-Total-Count") || 0
    }));
  },

  update: async (resource: string, params: Record<string, any>) => {
    params.data = await convertFilesToUpload(params.data);
    return authFetchJson(`${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource: string, params: Record<string, any>) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return authFetchJson(`${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  create: async (resource: string, params: Record<string, any>) => {
    params.data = await convertFilesToUpload(params.data);
    return authFetchJson(`${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id }
    }));
  },

  delete: (resource: string, params: Record<string, any>) =>
    authFetchJson(`${resource}/${params.id}`, {
      method: "DELETE"
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return authFetchJson(`${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  }
};

/**
 * Renders a resource based on the props passed in
 * @param {object} props
 */
export function RenderResource(props: any) {
  const {
    // #region Sanitize props that should not be passed to `<Resource />`
    category: _category,
    editId: _editId,
    hidden: _hidden,
    roles,
    // #endregion
    name,
    create,
    edit,
    icon,
    list,
    view,
    show,
    ...rest
  } = props;

  const { permissions } = usePermissions();
  // console.log({
  //   name,
  //   permissions,
  //   create: authorized(roles, permissions, "create"),
  //   edit: authorized(roles, permissions, "edit"),
  //   list: authorized(roles, permissions, "list"),
  //   show: authorized(roles, permissions, "show")
  // });

  return (
    <Resource
      key={name}
      name={name}
      create={authorized(roles, permissions, "create") ? create : null}
      edit={authorized(roles, permissions, "edit") ? edit : null}
      list={
        authorized(roles, permissions, "list")
          ? list
          : !!list
          ? props => <div>No permissions</div>
          : null
      }
      show={authorized(roles, permissions, "show") ? show : null}
      icon={icon}
      {...rest}
    />
  );
}

// Try to load the user information immediately if the token exists.
authUser.load();

//export * from "./socketIO";

// #region Typedefs
/** @typedef {"GET_LIST" | "GET_ONE" | "GET_MANY" | "GET_MANY_REFERENCE" | "CREATE" | "UPDATE" | "UPDATE_MANY" | "DELETE" | "DELETE_MANY"} DataActions */
/**
 * @typedef {(type:string,resource:string,params:any,submit:(type:string,resource:string,params:any)=>Promise)=>Promise} DataActionHandler
 */
/**
 * @typedef {object} AuthTokenInfo
 * @property {any} claims
 * @property {number} expiration
 * @property {number} iat
 * @property {boolean} loggedIn
 * @property {string[]} roles
 * @property {number|string} userId
 */
/**
 * @typedef {object} FetchJsonResponse
 * @property {number} status HTTP status code of the response.
 * @property {Headers} headers Standard Headers object for the response.
 * @property {string} body Text of the response body.
 * @property {object} [json] JSON parsed from the body text, if any.
 */
// #endregion
