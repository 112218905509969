export function getCategoryHierarchy(category) {
  const catArr = [];
  let cat = category;

  while (cat) {
    catArr.unshift(cat.name);
    cat = cat.parentCategory;
  }

  return concatItems(catArr, " > ");
}
export function getDurationStr(duration, showSeconds = true) {
  return `${duration / 3600 > 1 ? parseInt(duration / 3600) + " hr" : ""} ${duration / 60 > 1 ? parseInt((duration % 3600) / 60) + " min" : ""
    }${showSeconds ? " " + parseInt(duration % 60) + " sec" : ""}`.trim();
}
export const getFullSpeakerName = (speaker) =>
  concatItems([speaker.title, speaker.first_name, speaker.last_name]);

export const concatItems = (strArr, joiner = " ") =>
  strArr.filter((i) => !!i).join(joiner);

export const getMMDDYYYYFromDate = (date) => {
  return `${date.getMonth() + 1}/${date.getDay()}/${date.getFullYear()}`;
};
export const convertToObj = (array, sortIds) => {
  return array.reduce((acc, cur) => {
    if (sortIds) {
      sortIds.push(cur.id);
    }
    acc[cur.id + ""] = cur;
    return acc;
  }, {});
};

export const removeDupesObjArray = (curValues, newValues) => {
  if (!Array.isArray(curValues) || !Array.isArray(newValues)) {
    return [];
  }
  const curIds = curValues.map((v) => v.id);
  const newIds = newValues.map((v) => v.id);
  const removeDupesIds = Array.from(new Set([...curIds, ...newIds]));
  const toObjById = convertToObj([...curValues, ...newValues]);
  return removeDupesIds.map((id) => toObjById[id]);
};

/**
 * Displays a case name as "LastName FirstName-AdmitDate"
 * @param {Object} props
 */
export const DisplayName = ({ record = {}, source, parentRecord }) => {
  const { admit_date } = parentRecord;
  const { first_name, last_name } = record;
  return `${last_name}, ${first_name}-${admit_date}`;
};

export const getIntBoolValue = (value) => {
  if (typeof value === "boolean") {
    return value ? 1 : 0;
  }
  return value;
};

/**
 * Limit the length of a string with an optional parameter
 * to show an ellipsis at the end of the string.
 * @param {string} string
 * @param {number} limit
 * @param {boolean} showEllipsis [n]
 * @return {string} The truncated string
 */
export const limitStringSize = (string, limit, showEllipsis) => {
  if (typeof string !== "string" || string.length <= limit) {
    return string;
  }
  return showEllipsis
    ? string.substr(0, limit - 2) + "..."
    : string.substr(0, limit);
};

/**
 * Returns the integer value of a string.
 * For example "20" will return 20
 * @param {string} value
 */
export const getIntValue = (value) => {
  if (!value || isNaN(value)) {
    return value;
  }
  const intValue = parseInt(value);
  return intValue > 0 ? intValue : 0;
};

export const currencyFormat = (value) => {
  if (!value || isNaN(value)) {
    return value;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

export const getInitials = (name, props) => {
  if (!name) return name;
  const split = name.split(", ");
  if (split.length < 2) return "";
  return `${split[1].charAt(0)}${split[0].charAt(0)}`;
};

export function ensureUrlProtocol(url) {
  if (!url) return url;
  let validUrl = url;
  if (!url.startsWith("http")) {
    validUrl = "http://" + url;
  }
  return validUrl;
}

export function isValidEmail(email) {
  if (typeof email !== "string") return email;
  // eslint-disable-next-line no-useless-escape
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}
