import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  FunctionField,
  ImageField,
  ImageInput,
  List,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from "react-admin";
import { useForm, useFormState } from "react-final-form";
import { DeleteIcon } from "../design";

const useStyles = makeStyles(theme => ({
  hiddenInput: {
    width: 1,
    height: 1,
    overflow: "hidden"
  }
}));

const BannerList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="title" label="Title" />
      <TextField source="link_type" label="Link Type" />
      <TextField source="link_id" label="Link ID" />
      <FunctionField
        render={(record: any) =>
          !!record.file ? (
            <Link href={record.file.url} target="_blank">
              Banner Link
            </Link>
          ) : (
            <span>&nbsp;</span>
          )
        }
        label="Banner"
      />
      <DateField source="display_start" label="Start" />
      <DateField source="display_end" label="End" />
    </Datagrid>
  </List>
);

const BannerForm = (props: any) => {
  const [showFileUploader, setShowFileUploader] = useState(false);
  const {
    values: { link_type, file_id }
  } = useFormState();
  const form = useForm();
  const handleDeleteImg = () => {
    form.change("file_id", null);
  };
  const classes = useStyles();
  useEffect(() => {
    switch (link_type) {
      case "category":
      case "lecture":
      case "series":
      case "speaker":
        setShowFileUploader(false);
        break;

      case "live_event":
      case "url":
        setShowFileUploader(true);

        break;

      default:
        console.error("invalid link type");
        break;
    }
  }, [link_type]);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 600
      }}
    >
      <TextInput source="title" variant="outlined" />
      <DateInput source="display_start" variant="outlined" />
      <DateInput source="display_end" variant="outlined" />
      <SelectInput
        source="link_type"
        choices={[
          { id: "category", name: "Category" },
          { id: "lecture", name: "Lecture" },
          { id: "series", name: "Series" },
          // { id: "speaker", name: "Speaker" },
          { id: "live_event", name: "Live Event" },
          { id: "url", name: "URL" }
        ]}
        variant="outlined"
      />
      <TextInput source="link_id" label="Link ID" variant="outlined" />
      {file_id && (
        <Box
          style={{
            display: "flex"
          }}
        >
          <ImageField source="file.url" />
          <IconButton
            onClick={handleDeleteImg}
            style={{
              height: 50
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
      {showFileUploader ? (
        <ImageInput source="FILE_banner" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
      ) : (
        <Typography>
          Banners image upload are not supported for this link type.
        </Typography>
      )}
      <TextInput
        classes={{
          root: classes.hiddenInput
        }}
        type="hidden"
        source="file_id"
      />
    </Box>
  );
};

const BannerEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <BannerForm {...props} />
    </SimpleForm>
  </Edit>
);
const BannerCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <BannerForm {...props} />
    </SimpleForm>
  </Create>
);

export const Banners = {
  name: "banners",
  options: { label: "Banners", hideMenu: true },
  list: BannerList,
  edit: BannerEdit,
  create: BannerCreate,
  roles: ["all"]
};
