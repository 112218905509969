import axios from "axios";
import { getQueryVariable } from "../lib";
import { API_BASE_URL } from "../../config"
const { NODE_ENV } = process.env;

const __DEV__ = NODE_ENV === "development";

const { localStorage } = window;

const authToken =
  // get Token from url if passed in as querystring ?token=...
  getQueryVariable("token") ||
  // Token from react-admin:
  localStorage.getItem("token");

/** @param {AxiosRequestConfig} config */
function applyAuthHeaders(config) {
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
}

/** This is the API used for resources */
export const APIClient = axios.create({
  baseURL: API_BASE_URL,
});
if (authToken) {
  APIClient.interceptors.request.use(
    applyAuthHeaders,
    // CONSIDER: An error handler can be passed. (Useful for refresh token
    // logic, to retry requests after refreshing the access token.)
    // (err) => Promise.reject(err),
  );
}


if (__DEV__) {
  // Expose APIClient to developer...
  window.APIClient = APIClient;
}

export default APIClient;

// #region Typedefs

/** @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig */

// #endregion
