import {
  Button,
  Card,
  Grid,
  Link,
  makeStyles,
  Typography
} from "@material-ui/core";
// import {
import MicNoneIcon from "@material-ui/icons/MicNone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as React from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  FileField,
  Filter,
  FunctionField,
  List,
  minLength,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from "react-admin";
import { useForm } from "react-final-form";
import { useSelector } from "react-redux";
import { CategoryHierarchy } from "../components/common/CategoryHierarchy";
import { authClient } from "../server";
import { UploaderSelectors } from "../state";
import { main } from "../uploader/index";
import Uploader from "../uploader/Uploader.vm";
import { getDurationStr, getFullSpeakerName } from "../utils";

export const useStyles = makeStyles(theme => ({
  autoButton: {
    "& button": {
      boxShadow: "none"
    }
  }
}));
const ListFilters = (props: any) => (
  <Filter variant="outlined" {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <TextInput label="Search shiur titles" source="title" alwaysOn />
    <TextInput label="Duration" source="duration" alwaysOn />
    <TextInput label="Speaker" source="speaker" alwaysOn />
    <ReferenceInput
      {...props}
      source="category_id"
      reference="categories"
      perPage={-1}
      validate={required()}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput label="Active" source="active" defaultValue={true} />
  </Filter>
);

const validateHasData = [required(), minLength(4)];

const LectureList = (props: any) => (
  <List
    filters={<ListFilters />}
    {...props}
    exporter={false}
    title="Shiurim"
    perPage={50}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <FunctionField
        label="Duration"
        render={(record: any) => {
          return getDurationStr(record.duration, false);
        }}
      />
      <ReferenceField source="speaker_id" reference="speakers">
        <FunctionField
          render={(record: any) => {
            return <span>{getFullSpeakerName(record)}</span>;
          }}
        />
      </ReferenceField>
      <ReferenceField
        label="Category"
        source="category_id"
        reference="categories"
        link={e => ""} //This is here to prevent the nested <a warning
      >
        <FunctionField
          render={(record: any) => <CategoryHierarchy category={record} />}
        />
      </ReferenceField>
      {/* <ReferenceManyField
        label="Series"
        reference="series_lectures"
        target="lecture_id"
        // perPage={0}
      >
        <SingleFieldList>
          <ChipField source="series.name" />
        </SingleFieldList>
      </ReferenceManyField> */}

      <DateField source="date_recorded" />
      <BooleanField source="active" />
      {/* TODO: get views */}
      <FunctionField label="Views" render={() => ""} />
    </Datagrid>
  </List>
);

const LectureForm = (props: any) => {
  const classes = useStyles();
  const isEdit = !!props.record?.id;
  const form = useForm();
  const {
    record: { vimeo_id, id }
  } = props;

  const file = useSelector(UploaderSelectors.selectedUpload);
  const [tags, setTags] = React.useState<Record<string, any>[] | []>([]);
  const handleAutoCompleteUpdate = (_e: any, value: Record<string, any>[]) => {
    form.change("tags", value);
  };
  const handleLoadFromVimeo = async () => {
    const payload = {
      date_recorded: form.getFieldState("date_recorded").value,
      language_id: form.getFieldState("language_id").value,
      location_id: form.getFieldState("location_id").value,
      speaker_id: form.getFieldState("speaker_id").value,
      category_id: form.getFieldState("category_id").value,
      vimeo_id: form.getFieldState("vimeo_id").value,
      auto_display: form.getFieldState("auto_display").value,
      series_id: form.getFieldState("series_id").value,
      is_update: isEdit,
      lectureId: id
    };
    console.log({ payload });
    if (
      !payload.language_id ||
      !payload.location_id ||
      !payload.speaker_id ||
      !payload.category_id ||
      !payload.vimeo_id
    ) {
      alert(
        "Please make sure you have the following fields filled out: Language, Location, Speaker, Category, Vimeo ID"
      );
      return;
    }
    const response = await authClient
      .post("lectures/createLectureFromVimeo", payload, null, true)
      .then(d => d.json);
    window.location.href = "/#/lectures/" + response.lectureId;
  };

  React.useEffect(() => {
    if (file) {
      form.change("lecture_file", file);
    } else {
      form.change("lecture_file", undefined);
    }
  }, [form, file]);
  React.useEffect(() => {
    (async () => {
      main();
      const _tags: any = await authClient
        .get("tags", undefined, false)
        .then(d => d.json);
      setTags(_tags);
    })();
  }, []);
  return (
    <Grid
      style={{
        width: "100%"
      }}
      container
      spacing={3}
    >
      <Grid item xs={6}>
        <Card
          style={{
            padding: 25
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                <b>Basic Details</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput {...props} source="title" validate={validateHasData} />
            </Grid>
            <Grid item xs={12}>
              <TextInput {...props} multiline source="description" />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Additional Details</b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                {...props}
                type="date"
                source="date_recorded"
                InputLabelProps={{ shrink: true }}
                //Not defaulting the date since we will pull from vimeo
                // initialValue={format(new Date(), "yyyy-MM-dd")}
                validate={e => {
                  if (!e) {
                    return "Date is required";
                  }
                  const today = new Date();
                  const inputDate = new Date(e);
                  const currentTime = today.getTime();
                  const inputTime = inputDate.getTime();
                  if (currentTime < inputTime) {
                    return "Date can not be in the future";
                  }
                  return undefined;
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                {...props}
                source="speaker_id"
                reference="speakers"
                perPage={-1}
                validate={required()}
              >
                <SelectInput
                  variant="outlined"
                  optionText={(s: any) => {
                    return `${s.title} ${s.first_name} ${s.last_name}`;
                  }}
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                {...props}
                initialValue="1"
                source="language_id"
                reference="languages"
                validate={required()}
              >
                <SelectInput variant="outlined" optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput
                {...props}
                initialValue="1"
                source="location_id"
                reference="locations"
                validate={required()}
              >
                <SelectInput fullWidth variant="outlined" optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Categories & Tags</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ReferenceInput
                {...props}
                source="category_id"
                reference="categories"
                perPage={-1}
                validate={required()}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12}>
              <ReferenceInput
                {...props}
                source="series_id"
                reference="series"
                perPage={-1}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={12}>
              <>
                <TextInput {...props} label="Vimeo ID" source="vimeo_id" />
                {isEdit ? (
                  <Button onClick={handleLoadFromVimeo} color="secondary">
                    Update audio from vimeo ID
                  </Button>
                ) : (
                  <Button onClick={handleLoadFromVimeo} color="secondary">
                    Load Lecture data From Vimeo
                  </Button>
                )}
              </>
            </Grid>
            <Grid item xs={12}>
              {!!tags.length && (
                <Autocomplete
                  multiple
                  limitTags={-1}
                  options={tags}
                  defaultValue={form.getState().values.tags}
                  getOptionLabel={option => option.tag_name}
                  onChange={handleAutoCompleteUpdate}
                  renderInput={params => (
                    <TextInput
                      style={{
                        boxShadow: "none"
                      }}
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        classes: {
                          adornedEnd: classes.autoButton
                        }
                      }}
                      {...props}
                      source="tags"
                    />
                  )}
                />
              )}
              {isEdit && (
                <>
                  <TextInput
                    {...props}
                    disabled
                    label="Duration (seconds)"
                    source="duration"
                  />
                  {!!vimeo_id && (
                    <Link
                      {...props}
                      style={{
                        marginBottom: 24,
                        display: "block",
                        color: "rgb(0, 0, 238)",
                        textDecoration: "underline"
                      }}
                      target="_blank"
                      title="Vimeo Link"
                      href={`https://vimeo.com/${vimeo_id}`}
                    >
                      Vimeo Link
                    </Link>
                  )}
                  <FileField
                    {...props}
                    style={{
                      marginTop: 24,
                      marginBottom: 24,
                      display: "block"
                    }}
                    target="_blank"
                    title="Audio File Link"
                    source="audio_file_url"
                  />
                  <BooleanInput source="active" />
                </>
              )}
              <TextInput
                style={{ display: "none" }}
                source="lecture_file"
                type="hidden"
              />
            </Grid>
          </Grid>
          <BooleanInput
            defaultValue={true}
            label="Auto display when processing done"
            source="auto_display"
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Uploader />
      </Grid>
    </Grid>
  );
};

const LectureCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm variant="outlined">
        <LectureForm fullWidth />
      </SimpleForm>
    </Create>
  );
};

const LectureEdit = (props: any) => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm variant="outlined">
        <LectureForm fullWidth />
      </SimpleForm>
    </Edit>
  );
};

export const Lectures = {
  sort: 1,
  name: "lectures",
  options: { label: "Shiurim" },
  list: LectureList,
  icon: MicNoneIcon,
  edit: LectureEdit,
  create: LectureCreate,
  roles: ["all"]
};
